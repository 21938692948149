// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import {  select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Role, currentUserRole } from '../../../auth';

@Injectable()
export class MenuAsideService {
	// Public properties
	classes: string;
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	userRole$: Observable<Role>;

	/**
	 * Service Constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private menuConfigService: MenuConfigService
	) {
		this.loadMenu();
	}

	/**
	 * Load menu
	 */
	loadMenu() {
		// get menu list
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');

		this.userRole$ = this.store.pipe(select(currentUserRole));
		this.userRole$.subscribe(userRoleResp => {
			//console.log("loadMenu called inside");
			let hiddenStoreMenus = ['store', 'approve-images', 'regions'],
				hiddenRetailerMenus = ['approve-images', 'regions'];

			let companyType = localStorage.getItem('companyType');
			let userRole: any = localStorage.getItem('permissions');
				userRole = JSON.parse(userRole);

			let permissions: any = userRole.permissions;
			for (let menuItem of menuItems){
				for(let key in permissions){
					let value = permissions[key];

					if(key == menuItem.serverKey){
						menuItem.permission = (value.items.view.value) ? false : true;
					}

					if(companyType == 'mall' || companyType == 'retailer'){

						if(companyType == 'retailer'){
							if(hiddenRetailerMenus.indexOf(menuItem.serverKey) > -1){
								menuItem.permission = true;
							}
						}

						if(companyType == 'mall'){
							if(menuItem.serverKey == 'billing'){
								menuItem.permission = true;
							}
						}

						if(menuItem.serverKey == 'brand'){
							if(menuItem.page == 'store-info'){
								menuItem.permission = true;
							}
						}
					}
					else {

						if(hiddenStoreMenus.indexOf(menuItem.serverKey) > -1){
							menuItem.permission = true;
						}

						if(menuItem.serverKey == 'brand'){
							if(menuItem.page == 'mall-info'){
								menuItem.permission = true;
							}
						}
					}

				}
			}

			this.menuList$.next(menuItems);
		});
	}
}
