// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from "../../../../../core/auth/_services";
import { tap } from 'rxjs/operators';

@Component({
	selector: 'kt-delete-entity-dialog',
	templateUrl: './delete-entity-dialog.component.html'
})
export class DeleteEntityDialogComponent implements OnInit {
	// Public properties
	viewLoading: boolean = false;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		private auth: AuthService,
		public dialogRef: MatDialogRef<DeleteEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;

		console.log(this.data);
		let apiURL = "/admin/pads/remove",
			sendParam: any = { padId: this.data.recordId };

			if(this.data.recordType == 'product'){
				apiURL = "/admin/product/remove";
				sendParam = { productId: this.data.recordId };
			}

			if(this.data.recordType == 'AR'){
				apiURL = "/admin/arvideo/remove";
				sendParam = { arId: this.data.recordId };
			}

		if(this.data.recordType == 'store'){
			apiURL = "/admin/store/remove";
			sendParam = { storeId: this.data.recordId };
		}

		if(this.data.recordType == 'storeon'){
			apiURL = "/admin/store/onoff";
			sendParam = { storeId: this.data.recordId, status: 1 };
		}

		if(this.data.recordType == 'storeoff'){
			apiURL = "/admin/store/onoff";
			sendParam = { storeId: this.data.recordId, status: 0 };
		}

		if(this.data.recordType == 'region'){
			apiURL = "/admin/regions/remove";
			sendParam = { regionId: this.data.recordId };
		}

		if(this.data.recordType == 'beacon'){
			apiURL = "/admin/beacons/remove";
			sendParam = { beaconId: this.data.recordId };
		}

		if(this.data.recordType == 'system-user'){
			apiURL = "/admin/user/remove";
			sendParam = { userId: this.data.recordId, status: 0 };
		}

		if(this.data.recordType == 'system-user-block'){
			apiURL = "/admin/user/remove";
			sendParam = { userId: this.data.recordId, status: 2 };
		}

		if(this.data.recordType == 'system-user-unblock'){
			apiURL = "/admin/user/remove";
			sendParam = { userId: this.data.recordId, status: 1 };
		}

		if(this.data.recordType == 'role'){
			apiURL = "/admin/roles/remove";
			sendParam = { roleId: this.data.recordId };
		}

		this.auth.apiCall(sendParam, apiURL).pipe(tap(padResp => {
			this.dialogRef.close({ success: true });
		},
		error => {
			this.dialogRef.close(error.error);
		})).subscribe();

		//setTimeout(() => {
		//	this.dialogRef.close(true); // Keep only this row
		//}, 2500);
	}
}
