// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			/*NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',*/
			DASHBOARD: 'Dashboard',
			BRANDINFO: 'Brand Info',
			STOREINFO: 'Store Info',
			STORES: 'Stores',
			PADS: 'PADs',
			PRODUCTS: 'Products',
			REGIONS: 'Regions',
			BEACONS: 'Beacons',
			APPROVEIMAGES: 'Approve Images',
			ARVIDEOS:'AR Videos',
			SYSTEMUSER: 'System Users',
			USERS: 'Users',
			BLACKLIST: 'Blacklist',
			ROLES: 'Roles & Permission',
			ACCOUNT: 'Manage Account',
			MYACCOUNT: 'My Account',
			CHANGEPASSWORD: 'Change Password',
			BILLING: 'Billing'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password?',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
			},
			RESET: {
				TITLE: 'Reset Password',
				EXPIRE: 'Reset Link Expired',
			},
			FORGOT: {
				TITLE: 'Forgot Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Please check your email. We have sent a link to reset.',
				RESET: 'Your account password has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		ALERT: {
			TITLE: {
				SUCCESS: 'Success',
				ERROR: 'Error'
			},
			MESSAGE: {
				INFO_ADD_SUCCESS: 'Information added successfully.',
				INFO_UPDATE_SUCCESS: 'Information updated successfully.',
				OPENDAY_UPDATE_SUCCESS: 'Opened days information updated successfully.',
				CLOSEDAY_UPDATE_SUCCESS: 'Closed days information updated successfully.',
				IMAGE_UPDATE_SUCCESS: 'Image(s) added successfully.',
				IMAGE_REMOVE_SUCCESS: 'Image(s) removed successfully.',
				IMAGE_APPROVE_SUCCESS: 'Image(s) approved successfully.',
				IMAGE_REJECTED_SUCCESS: 'Image(s) rejected successfully.',
				MALL: {
					DELETE_MESSAGE: 'Deleted '
				},
				STORE: {
					DELETE_LIST_MESSAGE: 'Store removed successfully.'
				},
				PAD: {
					DELETE_LIST_MESSAGE: 'PAD removed successfully.'
				},
				PRODUCT: {
					DELETE_LIST_MESSAGE: 'Product removed successfully.'
				},
				AR: {
					DELETE_LIST_MESSAGE: 'ARVIDEOS removed successfully.'
				},
				REGION: {
					DELETE_LIST_MESSAGE: 'Region removed successfully.'
				},
				BEACON: {
					DELETE_LIST_MESSAGE: 'Beacon removed successfully.'
				},
				PROFILE: {
					PASSWORD_MESSAGE: 'Password updated successfully.',
					PROFILE_MESSAGE: 'Profile updated successfully.'
				},
				SYSTEM_USER: {
					CREATED: 'User added successfully.',
					UPDATED: 'User details updated successfully.',
					DELETE_LIST_MESSAGE: 'User blacklisted successfully.',
					DELETE_UNLIST_MESSAGE: 'User unblacklisted successfully.',
					DELETE_LIST_CONFIRM: 'User removed successfully.'
				},
				ROLE: {
					CREATED: 'Role added successfully.',
					UPDATED: 'Role updated successfully.',
					DELETE_LIST_MESSAGE: 'Role removed successfully.'
				}
			}
		},
		//Breadcrumb Titles
		'Dashboard': 'Dashboard',
		'Brand Info': 'Brand Info',
		'Brand': 'Brand',
		'Store Info': 'Store Info',
		'Stores': 'Stores',
		'ON':'ON',
		'OFF':'OFF',
		'PADs': 'PADs',
		'Regions': 'Regions',
		'Beacons': 'Beacons',
		'Approve Images': 'Approve Images',
		'System Users': 'System Users',
		'System User': 'System User',
		'Users': 'Users',
		'Blacklist': 'Blacklist',
		'Roles & Permission': 'Roles & Permission',
		'Manage Account': 'Manage Account',
		'My Account': 'My Account',
		'Change Password': 'Change Password',
		'Billing': 'Billing',
		'Image Approval': 'Image Approval',
		'Add Store': 'Add Store',
		'View Store': 'View Store',
		'Create Event': 'Create Event',
		'Create News': 'Create News',
		'Create Deal': 'Create Deal',
		'Edit PAD' : 'Edit PAD',
		'Add Region': 'Add Region',
		'Edit Region': 'Edit Region',
		'Add Beacon': 'Add Beacon',
		'Add ARVideos':'Add ARVideos',
		'Edit Beacon': 'Edit Beacon',
		'Add System Users': 'Add System Users',
		'Add Role': 'Add Role',
		'Edit Role': 'Edit Role',
		'Beacon': 'Beacon',
		'Add User': 'Add User',

		//BreadCrumb Description
		'Latest updates and statistic charts': 'Latest updates and statistic charts',
		'All the information about your brand': 'All the information about your brand',
		'All the information about stores': 'All the information about stores',
		'All the information about store': 'All the information about store',
		'Your proximity advertisments': 'Your proximity advertisments',
		'Your store fence': 'Your store fence',
		'Your proximity': 'Your proximity',
		'All the information about arvideos': 'All the information about arvideos',
		'approve images uploaded': 'approve images uploaded',
		'all the users of your system': 'all the users of your system',
		'deleted users of your system': 'deleted users of your system',
		'roles of your system': 'roles of your system',
		'add role to your system': 'add role to your system',
		'edit permission of the role': 'edit permission of the role',
		'update your profile.': 'update your profile.',
		'update your password.': 'update your password.',
		'All the information about your billing': 'All the information about your billing',

		//DASHBOARD
		'Stores Registered': 'Stores Registered',
		'Total Beacons Installed': 'Total Beacons Installed',
		'PADs Delivered': 'PADs Delivered',
		'Visitors': 'Visitors',

		//Brand Info
		'Overview': 'Overview',
		'Edit Profile': 'Edit Profile',
		'Opened / Closed on': 'Opened / Closed on',
		'Photos': 'Photos',
		'Contact Information': 'Contact Information',
		'Email': 'Email',
		'Website': 'Website',
		'Opening hours': 'Opening hours',
		'Timezone': 'Timezone',
		'Address': 'Address',
		'Description': 'Description',
		'Select main image': 'Select main image',
		'Brand name': 'Brand name',
		'Phone number': 'Phone number',
		'Location': 'Location',
		'Enter address': 'Enter address',
		'Social nets': 'Social nets',
		'Always open': 'Always open',
		'From': 'From',
		'To': 'To',
		'Opened Days': 'Opened Days',
		'Closed Days': 'Closed Days',
		'Drop files here': 'Drop files here',
		'Browse files': 'Browse files',

		//Search
		'Search': 'Search',
		'Search by name': 'Search by name',
		'Search by title': 'Search by title',
		'Search in role': 'Search in role',

		//Table Row Header
		'Store list': 'Store list',
		'Name': 'Name',
		'Contact Name': 'Contact Name',
		'Position': 'Position',
		'Status': 'Status',
		'Actions': 'Actions',
		'No data found': 'No data found',
		'PADs list': 'PADs list',
		'Title': 'Title',
		'Type': 'Type',
		'Start': 'Start',
		'End': 'End',
		'Store': 'Store',
		'Regions list': 'Regions list',
		'Beacons list': 'Beacons list',
		'ARVideos list': 'ARVideos list',
		'System user list': 'System user list',
		'Role': 'Role',
		'Date': 'Date',

		// Days
		'Mon': 'Mon',
		'Tue': 'Tue',
		'Wed': 'Wed',
		'Thur': 'Thur',
		'Fri': 'Fri',
		'Sat': 'Sat',
		'Sun': 'Sun',

		//Buttons
		'Add': 'Add',
		'Cancel': 'Cancel',
		'Save': 'Save',
		'Back': 'Back',
		'Add PAD': 'Add PAD',
		'Add Event': 'Add Event',
		'Add News': 'Add News',
		'Add Deal': 'Add Deal',
		'Close': 'Close',
		'Save changes': 'Save changes',
		'Approve': 'Approve',
		'Reject': 'Reject',
		'Approve all': 'Approve all',
		'Reject all': 'Reject all',
		'Sign Out': 'Sign Out',

		//Stores
		'Store name': 'Store name',
		'Store type': 'Store type',
		'Contact Person': 'Contact Person',
		'Created': 'Created',
		'Store delete': 'Store Delete',
		'Permanently delete store': 'Are you sure to permanently delete this store?',
		'Store being delete': 'Store is being deleting',
		'Store has deleted': 'Store has been deleted',

		//PADs
		'PAD Info': 'PAD Info',
		'Translate': 'Translate',
		'PAD Date': 'PAD Date',
		'Publication Date': 'Publication Date',
		'Link with store': 'Link with store',
		'Select Store': 'Select Store',
		'No record found': 'No record found',
		'Select timezone': 'Select timezone',
		'Local': 'Local',
		'UTC': 'UTC',
		'Notification countries': 'Notification countries',
		'Countries': 'Countries',
		'All Countries': 'All Countries',
		'Specific Countries': 'Specific Countries',
		'Select': 'Select',
		'Images': 'Images',
		'Exclude': 'Exclude',
		'Select Countries': 'Select Countries',
		'Locations': 'Locations',
		'Pad delete': 'PAD Delete',
		'Permanently delete pad': 'Are you sure to permanently delete this PAD?',
		'PAD being delete': 'PAD is being deleting',
		'PAD has deleted': 'PAD has been deleted',

		//Messages
		'No beacons available': 'No beacons available',
		'No locations available': 'No locations available',
		'No images for approval': 'No images for approval',
		'Loading please wait.': 'Loading please wait.',
		// 'No result found': 'No results found',
		'Enter valid hours': 'Please enter valid open hours.',
		'Enter required field': 'Please enter the required fields',
		'Required email': 'Please enter a valid email',
		'Select store type': 'Please select store type.',
		'Required information': 'Please enter required information.',
		'Publication pad date': 'where pad date should be equal to or in between publication date',
		'Total image size': 'Total image size per upload should be 10MB.',
		'Total 5 images': 'Total of 5 images are allowed per upload',
		'Enter region name': 'Please enter your region name.',
		'Alphabets and space': 'Only alphabets and space allowed.',
		'Enter beacon name': 'Please enter beacon name.',
		'Enter role name': 'Please enter role name.',
		'Enter first name': 'Please enter your first name.',
		'Enter last name': 'Please enter your last name.',
		'Enter your mail': 'Please enter your email.',
		'Enter only number': 'Please enter only numbers',

		//Regions & Beacons
		'Region Info': 'Region Info',
		'Region Name': 'Region Name',
		'Beacon Info': 'Beacon Info',
		'Beacon Name': 'Beacon Name',
		'Image & Videos Info':'Image & Videos Info',
		'Upload images':'Upload images',
		'Upload videos':'Upload videos',
		'UUID': 'UUID',
		'Major': 'Major',
		'Minor': 'Minor',
		'Region delete': 'Region Delete',
		'Permanently delete Region': 'Are you sure to permanently delete this Region?',
		'Region being delete': 'Region is being deleting...',
		'Region has deleted': 'Region has been deleted',
		'Beacon delete': 'Beacon Delete',
		'Permanently delete Beacon': 'Are you sure to permanently delete this Beacon?',
		'Beacon being delete': 'Beacon is being deleting...',
		'Beacon has deleted': 'Beacon has been deleted',

		// Users & Roles
		'User Info' : 'User Info',
		'First Name': 'First Name',
		'Last Name': 'Last Name',
		'Select role': 'Select role',
		'Password': 'Password',
		'Confirm Password': 'Confirm Password',
		'Authentication': 'Authentication',
		'Created By': 'Created By',
		'Role Info': 'Role Info',
		'Role Name': 'Role Name',
		'Permissions': 'Permissions',
		'Edit': 'Edit',
		'View': 'View',
		'Delete': 'Delete',
		'Current Password': 'Current Password',
		'New Password': 'New Password',
		'Hi': 'Hi',
		'Role delete': 'Role Delete',
		'Permanently delete role': 'Are you sure to permanently delete this role?',
		'Role being delete': 'Role is being deleting...',
		'Role has deleted': 'Role has been deleted',
		'User delete': 'User Delete',
		'Permanently delete user': 'Are you sure to permanently delete this user?',
		'User being delete': 'User is being deleting...',
		'User has deleted': 'User has been deleted',

		// Billing
		'BILLING': 'BILLING',
		'We appreciate your business': 'We appreciate your business',
		'Thanks for being a customer. A detailed summary': 'Thanks for being a customer. A detailed summary',
		'of your invoice is below.': 'of your invoice is below.',
		'If you have questions, we\'re happy to help.': 'If you have questions, we\'re happy to help.',
		'Email support@sevenjars.com or contact us': 'Email support@sevenjars.com or contact us',
		'through other support channels.': 'through other support channels.',
		'Your Plan': 'Your Plan',
		'Plan' : 'Plan',
		'Next Invoice': 'Next Invoice',
		'Trial Ends': 'Trial Ends',
		'Invoice History': 'Invoice History',
		'Pay': 'Pay',

		//Public Pages
		PUBLIC: {
			TITLE: 'Welcome to SevenJars!',
			DESCR: 'Grow your business using our Proximity Marketing technology',
			PRIVACY: 'Privacy',
			TERMS: 'Terms',
			SIGNIN: 'Sign In',
			CONTACT: 'Contact'
		}
	}
};
