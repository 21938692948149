//export { AuthService } from './auth.service.fake'; // You have to comment this, when your real back-end is done
export { AuthService } from './auth.service'; // You have to uncomment this, when your real back-end is done
export { HttpConfigInterceptor } from './httpconfig.interceptor';
export { CoursesService } from './courses.service';
export { CoursesDataSource } from './courses.datasource';
export { PadService } from './pad.service';
export { PadsDataSource } from './pads.datasource';
export { ProductService } from './product.service';
export { ProductsDataSource } from './products.datasource';
export { CompanyService } from './company.service';
export { CompanyDataSource } from './company.datasource';
export { SystemUserService } from './system-user.service';
export { SystemUserDataSource } from './system-user.datasource';
export { RoleDataSource } from './role.datasource';
export { RoleService } from './role.service';
export { RegionsService } from './regions.service';
export { RegionsDataSource } from './regions.datasource';
export { BeaconService } from './beacon.service';
export { BeaconDataSource } from './beacon.datasource';
export { BillingService } from './billing.service';
export { BillingDataSource } from './billing.datasource';
export { ArvideosService } from './arvideos.service';
export { ArvideosDataSource } from './arvideos.datasource';
