import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Arvideos } from "../_models/arvideos.model";
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArvideosService {
  URL: any = environment.apiUrl;

  constructor(private http:HttpClient) {}

  findVideosById(courseId: number): Observable<Arvideos> {
      return this.http.get<Arvideos>(`/admin/products/${courseId}`);
  }

  findPADs(padId:number, filter = '', sortOrder = 'asc', sortField= 'status', pageNumber = 0, pageSize = 3): Observable<Arvideos> {
      let companyType: string = localStorage.getItem('companyType'),
          companyId: string = localStorage.getItem('companyId');

      let params = new HttpParams()
          .set('search', filter)
          .set('order', sortOrder)
          .set('sort', sortField)
          .set('page', pageNumber.toString())
          .set('limit', pageSize.toString());

      if(companyType == 'store'){
          params.set('storeId', companyId);
      }

      let finalURL = this.URL + '/admin/arvideo/list';
      return this.http.get<Arvideos>(finalURL, {
          params: params
      }).pipe(
          map(res =>  res)
      );
  }

}
