export class CoursesData {
  id: string;
  company_name: string;
  phone_no: string;
}

export class Course {
  ts: number;
  success: boolean;
  error: boolean;
  data: CoursesData[];
  recordsTotal: number;
}
