import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Company, CompanyListResp } from "../_models";
import { environment } from '../../../../environments/environment';

@Injectable()
export class CompanyService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findCompanies(companyType: string, filter = '', sortOrder = 'asc', sortField= 'company_name', pageNumber = 0, pageSize = 3): Observable<CompanyListResp> {
        let finalURL = this.URL + '/admin/store/list';
        return this.http.get<CompanyListResp>(finalURL, {
            params: new HttpParams()
                .set('search', filter)
                .set('order', sortOrder)
                .set('sort', sortField)
                .set('page', pageNumber.toString())
                .set('limit', pageSize.toString())
        }).pipe(
            map(res =>  res)
        );
    }
}
