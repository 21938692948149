import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Products } from "../_models/products.model";
import { environment } from '../../../../environments/environment';

@Injectable()
export class ProductService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findProcutsById(courseId: number): Observable<Products> {
        return this.http.get<Products>(`/admin/products/${courseId}`);
    }

    findProducts(productName:string, filter = '', sortOrder = 'asc', sortField= 'productName', pageNumber = 0, pageSize = 3): Observable<Products> {
        let companyType: string = localStorage.getItem('companyType'),
            companyId: string = localStorage.getItem('companyId');

        let params = new HttpParams()
            .set('search', filter)
            .set('order', sortOrder)
            .set('sort', sortField)
            .set('page', pageNumber.toString())
            .set('limit', pageSize.toString());

        let finalURL = this.URL + '/admin/products/list';
        return this.http.get<Products>(finalURL, {
            params: params
        }).pipe(
            map(res =>  res)
        );
    }
}
