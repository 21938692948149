export class Beacons {
  id: string;
  beaconName: string;
  uuid: string;
  major: string;
  minor: string;
  location: any;
  latitude: string;
  longitude: string;
  companyId: string;
  companyName: string;

  clear() {
      this.id = '';
      this.beaconName = '';
      this.uuid = '';
      this.major = '';
      this.minor = '';
      this.location = { fullAddress: '' };
      this.latitude = '';
      this.longitude = '';
      this.companyId = '';
      this.companyName = '';
  }
}
