// Germany
export const locale = {
	lang: 'de',
	data: {
		TRANSLATOR: {
			SELECT: 'Wähle deine Sprache',
		},
		MENU: {
			/*NEW: 'Neu',
			ACTIONS: 'Aktionen',
			CREATE_POST: 'Erstellen Sie einen neuen Beitrag',
			PAGES: 'Pages',
			FEATURES: 'Eigenschaften',
			APPS: 'Apps',*/
			DASHBOARD: 'Dashboard',
			BRANDINFO: 'Markeninfo',
			STOREINFO: 'Geschäftsinformationen',
			STORES: 'Geschäfte',
			PADS: 'PADs',
			REGIONS: 'Regionen',
			BEACONS: 'Beacons',
			APPROVEIMAGES: 'Bilder freigeben',
			SYSTEMUSER: 'Systembenutzer',
			USERS: 'Benutzer',
			BLACKLIST: 'Blacklist',
			ROLES: 'Benutzerrollen & Berechtigungen',
			ACCOUNT: 'Konto verwalten',
			MYACCOUNT: 'Mein Konto',
			CHANGEPASSWORD: 'Passwort ändern',
			BILLING: 'Rechnungsstellung'
		},
		AUTH: {
			GENERAL: {
				OR: 'Oder',
				SUBMIT_BUTTON: 'einreichen',
				NO_ACCOUNT: 'Hast du kein Konto?',
				SIGNUP_BUTTON: 'Anmelden',
				FORGOT_BUTTON: 'Passwort vergessen?',
				BACK_BUTTON: 'Zurück',
				PRIVACY: 'Privatsphäre',
				LEGAL: 'Legal',
				CONTACT: 'Kontakt',
			},
			LOGIN: {
				TITLE: 'Create Account',
				BUTTON: 'Einloggen',
			},
			FORGOT: {
				TITLE: 'Forgot Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Ihr Account wurde erfolgreich zurückgesetzt'
			},
			REGISTER: {
				TITLE: 'Registriere dich',
				DESC: 'Geben Sie Ihre Daten ein, um Ihr konto zu erstellen',
				SUCCESS: 'Ihr Account wurde erfolgreich registriert'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Vollständiger Name',
				PASSWORD: 'Passwort',
				CONFIRM_PASSWORD: 'Passwort bestätigen',
				USERNAME: 'Nutzername'
			},
			VALIDATION: {
				INVALID: '{{name}} Ist ungültig',
				REQUIRED: '{{name}} Wird benötigt',
				MIN_LENGTH: '{{name}} Die minimale Feldlänge beträgt {{min}}',
				AGREEMENT_REQUIRED: 'Akzeptierende Geschäftsbedingungen sind erforderlich',
				NOT_FOUND: 'Die angeforderte {{name}} Wurde nicht gefunden',
				INVALID_LOGIN: 'Die Anmeldedaten sind falsch',
				REQUIRED_FIELD: 'Pflichtfeld',
				MIN_LENGTH_FIELD: 'Minimale Feldlänge:',
				MAX_LENGTH_FIELD: 'Maximale Feldlänge:',
				INVALID_FIELD: 'Feld ist nicht gültig',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Die ausgewählten Datensätze zählen: ',
				ALL: 'Alles',
				SUSPENDED: 'Suspendiert',
				ACTIVE: 'Aktiv',
				FILTER: 'Filter',
				BY_STATUS: 'nach Status',
				BY_TYPE: 'nach Typ',
				BUSINESS: 'Geschäft',
				INDIVIDUAL: 'Individuell',
				SEARCH: 'Suchen',
				IN_ALL_FIELDS: 'in allen Feldern'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Kunden',
				CUSTOMERS_LIST: 'Kundenliste',
				NEW_CUSTOMER: 'Neukunde',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Kunde löschen',
					DESCRIPTION: 'Sind Sie sicher, dass Sie diesen Kunden dauerhaft löschen möchten?',
					WAIT_DESCRIPTION: 'Kunde wird gelöscht...',
					MESSAGE: 'Kunde wurde gelöscht'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Kunde löschen',
					DESCRIPTION: 'Sind Sie sicher, dass Sie diesen Kunden dauerhaft löschen möchten?',
					WAIT_DESCRIPTION: 'Kunde wird gelöscht...',
					MESSAGE: 'Kunde wurde gelöscht'
				},
				UPDATE_STATUS: {
					TITLE: 'Der Status wurde für ausgewählte Kunden aktualisiert',
					MESSAGE: 'Der ausgewählte Kundenstatus wurde erfolgreich aktualisiert'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Kunde wurde aktualisiert',
					ADD_MESSAGE: 'Kunde wurde angelegt'
				}
			}
		},
		ALERT: {
			TITLE: {
				SUCCESS: 'Success',
				ERROR: 'Error'
			},
			MESSAGE: {
				INFO_ADD_SUCCESS: 'Information added successfully.',
				INFO_UPDATE_SUCCESS: 'Information updated successfully.',
				OPENDAY_UPDATE_SUCCESS: 'Opened days information updated successfully.',
				CLOSEDAY_UPDATE_SUCCESS: 'Closed days information updated successfully.',
				IMAGE_UPDATE_SUCCESS: 'Image(s) added successfully.',
				IMAGE_REMOVE_SUCCESS: 'Image(s) removed successfully.',
				IMAGE_APPROVE_SUCCESS: 'Image(s) approved successfully.',
				IMAGE_REJECTED_SUCCESS: 'Image(s) rejected successfully.',
				MALL: {
					DELETE_MESSAGE: 'Deleted '
				},
				STORE: {
					DELETE_LIST_MESSAGE: 'Store removed successfully.'
				},
				PAD: {
					DELETE_LIST_MESSAGE: 'PAD removed successfully.'
				},
				REGION: {
					DELETE_LIST_MESSAGE: 'Region removed successfully.'
				},
				BEACON: {
					DELETE_LIST_MESSAGE: 'Beacon removed successfully.'
				},
				PROFILE: {
					PASSWORD_MESSAGE: 'Password updated successfully.',
					PROFILE_MESSAGE: 'Profile updated successfully.'
				},
				SYSTEM_USER: {
					CREATED: 'User added successfully.',
					UPDATED: 'User details updated successfully.',
					DELETE_LIST_MESSAGE: 'User blacklisted successfully.',
					DELETE_LIST_CONFIRM: 'User removed successfully.'
				},
				ROLE: {
					CREATED: 'Role added successfully.',
					UPDATED: 'Role updated successfully.',
					DELETE_LIST_MESSAGE: 'Role removed successfully.'
				}
			}
		},
		'Dashboard': 'Dashboard',
		'Brand Info': 'Markeninfo',
		'Brand': 'Marke',
		'Store Info': 'Geschäftsinformationen',
		'Stores': 'Geschäfte',
		'PADs': 'PADs',
		'Regions': 'Regionen',
		'Beacons': 'Beacons',
		'Approve Images': 'Bilder freigeben',
		'System Users': 'Systembenutzer',
		'System User': 'Systembenutzer',
		'Users': 'Benutzer',
		'Blacklist': 'Blacklist',
		'Roles & Permission': 'Benutzerrollen & Berechtigungen',
		'Manage Account': 'Konto verwalten',
		'My Account': 'Mein Konto',
		'Change Password': 'Passwort ändern',
		'Billing': 'Rechnungsstellung',
		'Image Approval': 'Bildfreigabe',
		'Add Store': 'Shop hinzufügen',
		'View Store': 'Shop anzeigen',
		'Create Event': 'Veranstaltung erstellen',
		'Create News': 'Neuigkeiten erstellen',
		'Create Deal': 'Angebot erstellen',
		'Edit PAD' : 'PAD bearbeiten',
		'Add Region': 'Region hinzufügen',
		'Edit Region': 'Region bearbeiten',
		'Add Beacon': 'Beacon hinzufügen',
		'Edit Beacon': 'Beacon bearbeiten',
		'Add System Users': 'Systembenutzer hinzufügen',
		'Add Role': 'Benutzerrolle hinzufügen',
		'Edit Role': 'Rolle bearbeiten',
		'Beacon': 'Beacon',
		'Add User': 'Benutzer hinzufügen',

		//BreadCrumb Description
		'Latest updates and statistic charts': 'Aktuelle Updates und Statistik-Diagramme',
		'All the information about your brand': 'Alle Informationen über Ihre Marke',
		'All the information about stores': 'Alle Informationen über die Geschäfte',
		'All the information about store': 'Alle Informationen über die Geschäfte',
		'Your proximity advertisments': 'Ihre Proximity-Anzeigen',
		'Your store fence': 'Your store fence',
		'Your proximity': 'Ihre Präsenz',
		'approve images uploaded': 'Bilder, die hochgeladen wurden, genehmigen',
		'all the users of your system': 'alle Benutzer Ihres Systems',
		'deleted users of your system': 'gelöschte Benutzer Ihres Systems',
		'roles of your system': 'Rollen Ihres Systems',
		'add role to your system': 'fügen Sie Ihrem System eine Benutzerrolle hinzu',
		'edit permission of the role': 'Bearbeitungsberechtigung der Rolle',
		'update your profile.': 'Aktualisieren Sie Ihr Profil',
		'update your password.': 'Aktualisieren Sie Ihr Passwort.',
		'All the information about your billing': 'Alle Informationen zu Ihre Abrechnung',

		//DASHBOARD
		'Stores Registered': 'Registrierte Geschäfte',
		'Total Beacons Installed': 'Insgesamt installierte Beacons',
		'PADs Delivered': 'PADs bereitgestellt',
		'Visitors': 'Besucher',

		//Brand Info
		'Overview': 'Übersicht',
		'Edit Profile': 'Profil bearbeiten',
		'Opened / Closed on': 'Geöffnet / Geschlossen am ',
		'Photos': 'Fotos',
		'Contact Information': 'Kontaktinformationen',
		'Email': 'E-mail',
		'Website': 'E-mail',
		'Opening hours': 'Öffnungszeiten',
		'Timezone': 'Zeitzone',
		'Address': 'Adresse',
		'Description': 'Beschreibung',
		'Select main image': 'Hauptbild auswählen',
		'Brand name': 'Bezeichnung',
		'Phone number': 'Telefonnummer',
		'Location': 'Standort',
		'Enter address': 'Adresse eingeben',
		'Social nets': 'Soziale Netzwerke',
		'Always open': 'Immer offen',
		'From': 'Von',
		'To': 'Bis',
		'Opened Days': 'Geöffnete Tage',
		'Closed Days': 'Ruhetage',
		'Drop files here': 'Dateien hier ablegen',
		'Browse files': 'Dateien durchsuchen',

		//Search
		'Search': 'Suchen',
		'Search by name': 'Nach Namen suchen',
		'Search by title': 'Suche nach Titel',
		'Search in role': 'Suche in rolle',

		//Table Row Header
		'Store list': 'Filialliste',
		'Name': 'Name',
		'Contact Name': 'Ansprechpartner',
		'Position': 'Postion',
		'Status': 'Status',
		'Actions': 'Aktionen',
		'No data found': 'Keine Informationen gefunden',
		'PADs list': 'PADs-Liste',
		'Title': 'Titel',
		'Type': 'Typ',
		'Start': 'Start',
		'End': 'Ende',
		'Store': 'Geschäft',
		'Regions list': 'Liste der Regionen',
		'Beacons list': 'Beacons-Liste',
		'System user list': 'Systembenutzerliste',
		'Role': 'Rolle',
		'Date': 'Datum',

		// Days
		'Mon': 'Mo',
		'Tue': 'Die',
		'Wed': 'Mi',
		'Thur': 'Do',
		'Fri': 'Fr',
		'Sat': 'Sa',
		'Sun': 'So',

		//Buttons
		'Add': 'Hinzufügen',
		'Cancel': 'Abbrechen',
		'Save': 'Speichern',
		'Back': 'Back',
		'Add PAD': 'PAD hinzufügen',
		'Add Event': 'Event hinzufügen',
		'Add News': 'Neuigkeiten hinzufügen',
		'Add Deal': 'Angebot hinzufügen',
		'Close': 'Schließen',
		'Save changes': 'Änderungen speichern',
		'Approve': 'Approve',
		'Reject': 'Reject',
		'Approve all': 'Alle genehmigen',
		'Reject all': 'Alle ablehnen',
		'Sign Out': 'Abmeldung',

		//Stores
		'Store name': 'Name des Geschäfts',
		'Store type': 'Geschäftsart',
		'Contact Person': 'Ansprechpartner',
		'Created': 'Created',
		'Store delete': 'Shop löschen',
		'Permanently delete store': 'Sind Sie sicher, dass Sie diesen Shop dauerhaft löschen möchten?',
		'Store being delete': 'Shop wird gelöscht...',
		'Store has deleted': 'Shop wurde gelöscht',

		//PADs
		'PAD Info': 'PAD-Informationen',
		'Translate': 'Übersetzen',
		'PAD Date': 'PAD Datum',
		'Publication Date': 'Veröffentlichungsdatum',
		'Link with store': 'Link mit dem Geschäft',
		'Select Store': 'Geschäft auswählen',
		'No record found': 'Kein Eintrag gefunden',
		'Select timezone': 'Wähle Yeityone aus',
		'Local': 'Lokal',
		'UTC': 'UTC',
		'Notification countries': 'Benachrichtigungsländer',
		'Countries': 'Länder',
		'All Countries': 'Alle Länder',
		'Specific Countries': 'Spezifische Länder',
		'Select': 'Select',
		'Images': 'Bilder',
		'Exclude': 'Ausschließen',
		'Select Countries': 'Wählen Sie die Länder aus',
		'Locations': 'Standorte',
		'Pad delete': 'PAD löschen',
		'Permanently delete pad': 'Sind Sie sicher, dass Sie diesen PAD dauerhaft löschen möchten?',
		'PAD being delete': 'PAD wird gelöscht...',
		'PAD has deleted': 'PAD wure gelöscht',

		//Messages
		'No beacons available': 'Keine Beacons verfügbar',
		'No locations available': 'Keine Standorte verfügbar',
		'No images for approval': 'No images for approval',
		'Loading please wait.': 'Laden, bitte warten',
		// 'No result found': 'No results found',
		'Enter valid hours': 'Bitte geben Sie eine gültige Öffnungszeit ein',
		'Enter required field': 'Bitte füllen Sie die erforderlichen Felder aus',
		'Required email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
		'Select store type': 'Please select store type.',
		'Required information': 'Please enter required information.',
		'Publication pad date': 'Das Datum des PAD muss gleich sein oder zwischen dem Veröffentlichungsdatum liegen',
		'Total image size': 'Das Gesamtgewicht des Bildes sollte 10 MB betragen',
		'Total 5 images': 'Es sind insgesamt 5 Bilder zulässig',
		'Enter region name': 'Bitte geben Sie den Namen der Region ein',
		'Alphabets and space': 'Es sind nur Buchstaben und Leerzeichen zulässig',
		'Enter beacon name': 'Bitte geben Sie den Namen des Beacons ein',
		'Enter role name': 'Bitte geben Sie den Namen der Rolle ein',
		'Enter first name': 'Bitte geben Sie ihren Namen ein',
		'Enter last name': 'Bitte geben Sie ihren Nachname ein',
		'Enter your mail': 'Bitte geben Sie ihre E-Mail-Adresse',
		'Enter only number': 'Bitte geben Sie nur Zahlen ein',

		//Regions & Beacons
		'Region Info': 'Region-Informationen',
		'Region Name': 'Name der Region',
		'Beacon Info': 'Beacon Info',
		'Beacon Name': 'Beacon Name',
		'UUID': 'UUID',
		'Major': 'Wichtig',
		'Minor': 'Nebensächlich',
		'Region delete': 'Region löschen',
		'Permanently delete Region': 'Sind Sie sicher, dass Sie diese Region dauerhaft löschen möchten?',
		'Region being delete': 'Region wird gelöscht',
		'Region has deleted': 'Region wurde gelöscht',
		'Beacon delete': 'Beacon löschen',
		'Permanently delete Beacon': 'Sind Sie sicher, dass Sie dieses Beacon dauerhaft löschen möchten?',
		'Beacon being delete': 'Beacon wir gelöscht...',
		'Beacon has deleted': 'Beacon wurde gelöscht',

		// Users & Roles
		'User Info' : 'Benutzerinformation',
		'First Name': 'Vorname',
		'Last Name': 'Nachname',
		'Select role': 'Rolle auswählen',
		'Password': 'Passwort',
		'Confirm Password': 'Passwort bestätigen',
		'Authentication': 'Authentifizierung',
		'Created By': 'Erstellt von',
		'Role Info': 'Rollen-Info',
		'Role Name': 'Name der Rolle',
		'Permissions': 'Berechtigungen',
		'Edit': 'Edit',
		'View': 'View',
		'Delete': 'Delete',
		'Current Password': 'Aktuelles Passwort',
		'New Password': 'Neues Passwort',
		'Hi': 'Hallo',
		'Role delete': 'Rolle löschen',
		'Permanently delete role': 'Sind Sie sicher, dass Sie diese Beacon dauerhaft löschen möchten?',
		'Role being delete': 'Rolle wird gelöscht...',
		'Role has deleted': 'Rolle wurde gelöscht',
		'User delete': 'Benutzer löschen',
		'Permanently delete user': 'Sind Sie sicher, dass Sie diesen Benutzer dauerhaft löschen möchten?',
		'User being delete': 'Benutzer wird gelöscht',
		'User has deleted': 'Benutzer wurde gelöscht',

		// Billing
		'BILLING': 'Rechnungsstellung',
		'We appreciate your business': 'We appreciate your business',
		'Thanks for being a customer. A detailed summary': 'Thanks for being a customer. A detailed summary',
		'of your invoice is below.': 'of your invoice is below.',
		'If you have questions, we\'re happy to help.': 'If you have questions, we\'re happy to help.',
		'Email support@sevenjars.com or contact us': 'Email support@sevenjars.com or contact us',
		'through other support channels.': 'through other support channels.',
		'Your Plan': 'Your Plan',
		'Plan' : 'Plan',
		'Next Invoice': 'Nächste Abrechnung',
		'Trial Ends': 'Der Prozess endet',
		'Invoice History': 'Invoice History',
		'Pay': 'Pay',

		//Public Pages
		PUBLIC: {
			TITLE: 'Wilkommen bei NearGreen!',
			DESCR: 'Grow your business using our Proximity Marketing technology',
			PRIVACY: 'Privacy',
			TERMS: 'Bedingungen',
			SIGNIN: 'Einloggen',
			CONTACT: 'Kontakt'
		}
	}
};
