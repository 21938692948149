import { Injectable } from '@angular/core';
//import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpParams
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    private totalRequests: number = 1;
    private ignoreLoadURLs: string[] = ["/admin/drop/down"];

    constructor(public loader: LoadingBarService, private router: Router) { } //public errorDialogService: ErrorDialogService
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestURL = request.url.replace(environment.apiUrl,"");

        if(request.url.indexOf('/v1/admin') > -1){
            //console.log(this.totalRequests);
            if(this.ignoreLoadURLs.indexOf(requestURL) == -1){
                if(this.totalRequests == 1){
                    this.loader.start();
                }
                this.totalRequests++;
            }


            let encrypted = CryptoJS.HmacSHA1(request.body, environment.apiSecret);
            let encryptedHex = encrypted.toString(CryptoJS.enc.Hex);

            let authToken = localStorage.getItem('accessToken');
            if(authToken != ''){
                if (!request.headers.has('Authorization')) {
                    request = request.clone({ headers: request.headers.set('Authorization', "Bearer "+authToken) });
                }
            }

            if (!request.headers.has('API-KEY')) {
                request = request.clone({ headers: request.headers.set('API-KEY', environment.apiKey) });
            }

            if(request.url.indexOf('/v1/admin/image/upload') == -1){

                let companyId = localStorage.getItem('companyId');
                let companyType = localStorage.getItem('companyType');

                if(companyId != '' && companyId != null){
                    if(request.method == 'POST'){
                        if(request.body == null){
                            if(companyType == 'mall' || companyType == 'retailer'){
                                request = request.clone({ body: {...request.body, mallId: companyId, loginType: companyType } });
                            }
                            else {
                                request = request.clone({ body: {...request.body, storeId: companyId, loginType: companyType } });
                            }
                        }
                        else{
                            if(companyType == 'mall' || companyType == 'retailer') {
                                //request.body.mallId = companyId;
                                request = request.clone({ body: {...request.body, mallId: companyId, loginType: companyType } });
                            }
                            else {
                                request = request.clone({ body: {...request.body, storeId: companyId, loginType: companyType } });
                                //request.body.storeId = companyId;
                            }
                        }
                    }
                    else {
                        let params = request.params;
                        if(companyType == 'mall' || companyType == 'retailer') {
                            params = params.append('mallId', companyId);
                            params = params.append('loginType', companyType);
                            request = request.clone({ body: {...request.body, mallId: companyId, loginType: companyType }, params: params });
                        }
                        else {
                            params = params.append('storeId', companyId);
                            params = params.append('loginType', companyType);
                            request = request.clone({ body: {...request.body, storeId: companyId, loginType: companyType }, params: params });
                        }
                        //request.params = params;
                    }
                }

                if (!request.headers.has('Content-Type')) {
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }
            }
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                    if(request.url.indexOf('/v1/admin') > -1){
                        this.decreaseRequests(requestURL);
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };

                //this.errorDialogService.openDialog(data);
                if(request.url.indexOf('/v1/admin') > -1){
                    this.decreaseRequests(requestURL);
                }

                if(error.status == 403 || error.status == 410){
                    let language = localStorage.getItem('language');
                    localStorage.removeItem(environment.authTokenKey);
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userId");
                    localStorage.removeItem("companyId");
                    localStorage.removeItem("permissions");
                    localStorage.removeItem("companyType");
                    localStorage.clear();
                    localStorage.setItem('language', language);
                    this.router.navigateByUrl('/auth/login');
                    
                }
                else
                {
                return throwError(error);
                }
                  
            }));
    }

    private decreaseRequests(requestURL: string) {
        if(this.ignoreLoadURLs.indexOf(requestURL) == -1){
            //console.log(this.totalRequests);
            if(this.totalRequests > 1){
                this.totalRequests--;
            }

            if (this.totalRequests <= 1) {
                this.totalRequests = 1;
                this.loader.complete();
            }
        }
    }
}
