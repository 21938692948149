import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Billing, BillingListResp } from "../_models";
import { environment } from '../../../../environments/environment';

@Injectable()
export class BillingService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findBilling(filter = '', sortOrder = 'asc', sortField= 'firstName', pageNumber = 0, pageSize = 3, status = 1): Observable<BillingListResp> {
        let finalURL = this.URL + '/admin/billing/list';
        return this.http.post<BillingListResp>(finalURL ,
            {
            params: new HttpParams()
                .set('search', filter)
                .set('order', sortOrder)
                .set('sort', sortField)
                .set('page', pageNumber.toString())
                .set('limit', pageSize.toString())
                .set('status', status.toString())
                .set('companyId', localStorage.getItem('companyId'))
        }
        ).pipe(
            map(res =>  res)
        );
    }
}
