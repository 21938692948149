// France
export const locale = {
	lang: 'fr',
	data: {
		TRANSLATOR: {
			SELECT: 'choisissez votre langue',
		},
		MENU: {
			/*NEW: 'Nouveau',
			ACTIONS: 'Actes',
			CREATE_POST: 'Créer un nouveau Post',
			PAGES: 'Pages',
			FEATURES: 'Fonctionnalités',
			APPS: 'Applications',*/
			DASHBOARD: 'Panneau de commande',
			BRANDINFO: 'Informations sur la marque',
			STOREINFO: 'Liste de magasin',
			STORES: 'Magasins',
			PADS: 'PADs',
			REGIONS: 'Regions',
			BEACONS: 'Balises',
			APPROVEIMAGES: 'Approuver les images',
			SYSTEMUSER: 'Utilisateurs du système',
			USERS: 'Utilisateurs',
			BLACKLIST: 'Liste noire',
			ROLES: 'Rôles & Permission',
			ACCOUNT: 'Gérer son compte',
			MYACCOUNT: 'Mon compte',
			CHANGEPASSWORD: 'Changer mot de passe',
			BILLING: 'Facturation'
		},
		AUTH: {
			GENERAL: {
				OR: 'Ou',
				SUBMIT_BUTTON: 'Soumettre',
				NO_ACCOUNT: 'Ne pas avoir de compte?',
				SIGNUP_BUTTON: 'Registre',
				FORGOT_BUTTON: 'Mot de passe oublié?',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Créer un compte',
				BUTTON: 'Sign In',
			},
			FORGOT: {
				TITLE: 'Forgot Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Mot de passe',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Nom d\'utilisateur'
			},
			VALIDATION: {
				INVALID: '{{name}} n\'est pas valide',
				REQUIRED: '{{name}} est requis',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Nombre d\'enregistrements sélectionnés: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'éCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Les clients',
				CUSTOMERS_LIST: 'Liste des clients',
				NEW_CUSTOMER: 'Nouveau client',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Suppression du client',
					DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement ce client?',
					WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
					MESSAGE: 'Le client a été supprimé'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Supprimer les clients',
					DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
					WAIT_DESCRIPTION: 'Les clients suppriment ...',
					MESSAGE: 'Les clients sélectionnés ont été supprimés'
				},
				UPDATE_STATUS: {
					TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
					MESSAGE: 'Le statut des clients sélectionnés a été mis à jour avec succès'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Le client a été mis à jour',
					ADD_MESSAGE: 'Le client a été créé'
				}
			}
		},
		ALERT: {
			TITLE: {
				SUCCESS: 'Success',
				ERROR: 'Error'
			},
			MESSAGE: {
				INFO_ADD_SUCCESS: 'Information added successfully.',
				INFO_UPDATE_SUCCESS: 'Information updated successfully.',
				OPENDAY_UPDATE_SUCCESS: 'Opened days information updated successfully.',
				CLOSEDAY_UPDATE_SUCCESS: 'Closed days information updated successfully.',
				IMAGE_UPDATE_SUCCESS: 'Image(s) added successfully.',
				IMAGE_REMOVE_SUCCESS: 'Image(s) removed successfully.',
				IMAGE_APPROVE_SUCCESS: 'Image(s) approved successfully.',
				IMAGE_REJECTED_SUCCESS: 'Image(s) rejected successfully.',
				MALL: {
					DELETE_MESSAGE: 'Deleted '
				},
				STORE: {
					DELETE_LIST_MESSAGE: 'Store removed successfully.'
				},
				PAD: {
					DELETE_LIST_MESSAGE: 'PAD removed successfully.'
				},
				REGION: {
					DELETE_LIST_MESSAGE: 'Region removed successfully.'
				},
				BEACON: {
					DELETE_LIST_MESSAGE: 'Beacon removed successfully.'
				},
				PROFILE: {
					PASSWORD_MESSAGE: 'Password updated successfully.',
					PROFILE_MESSAGE: 'Profile updated successfully.'
				},
				SYSTEM_USER: {
					CREATED: 'User added successfully.',
					UPDATED: 'User details updated successfully.',
					DELETE_LIST_MESSAGE: 'User blacklisted successfully.',
					DELETE_LIST_CONFIRM: 'User removed successfully.'
				},
				ROLE: {
					CREATED: 'Role added successfully.',
					UPDATED: 'Role updated successfully.',
					DELETE_LIST_MESSAGE: 'Role removed successfully.'
				}
			}
		},
		//Breadcrumb Titles
		'Dashboard': 'Dashboard',
		'Brand Info': 'Brand Info',
		'Brand': 'Brand',
		'Store Info': 'Store Info',
		'Stores': 'Stores',
		'PADs': 'PADs',
		'Regions': 'Regions',
		'Beacons': 'Beacons',
		'Approve Images': 'Approve Images',
		'System Users': 'System Users',
		'System User': 'System User',
		'Users': 'Users',
		'Blacklist': 'Blacklist',
		'Roles & Permission': 'Roles & Permission',
		'Manage Account': 'Manage Account',
		'My Account': 'My Account',
		'Change Password': 'Change Password',
		'Billing': 'Billing',
		'Image Approval': 'Image Approval',
		'Add Store': 'Add Store',
		'View Store': 'View Store',
		'Create Event': 'Create Event',
		'Create News': 'Create News',
		'Create Deal': 'Create Deal',
		'Edit PAD' : 'Edit PAD',
		'Add Region': 'Add Region',
		'Edit Region': 'Edit Region',
		'Add Beacon': 'Add Beacon',
		'Edit Beacon': 'Edit Beacon',
		'Add System Users': 'Add System Users',
		'Add Role': 'Add Role',
		'Edit Role': 'Edit Role',
		'Beacon': 'Beacon',
		'Add User': 'Add User',

		//BreadCrumb Description
		'Latest updates and statistic charts': 'Latest updates and statistic charts',
		'All the information about your brand': 'All the information about your brand',
		'All the information about stores': 'All the information about stores',
		'All the information about store': 'All the information about store',
		'Your proximity advertisments': 'Your proximity advertisments',
		'Your store fence': 'Your store fence',
		'Your proximity': 'Your proximity',
		'approve images uploaded': 'approve images uploaded',
		'all the users of your system': 'all the users of your system',
		'deleted users of your system': 'Utilisateurs supprimés de votre système',
		'roles of your system': 'rôles de votre système',
		'add role to your system': 'Ajouter une fonction à votre système',
		'edit permission of the role': 'autorisation dédition du rôle',
		'update your profile.': 'Mettre à jour votre profil',
		'update your password.': 'mettre à jour votre mot de passe',
		'All the information about your billing': 'Toutes les informations sur votre facturation',

		//DASHBOARD
		'Stores Registered': 'Magasins enregistrés',
		'Total Beacons Installed': 'Nombre total de balises installées',
		'PADs Delivered': 'PADs livrés',
		'Visitors': 'Visiteurs',

		//Brand Info
		'Overview': 'Vue densemble',
		'Edit Profile': 'Editer le profil',
		'Opened / Closed on': 'Ouvert / Fermé le',
		'Photos': 'Photos',
		'Contact Information': 'Informations de contact',
		'Email': 'Email',
		'Website': 'Email',
		'Opening hours': 'Heures douverture',
		'Timezone': 'Fuseau horaire',
		'Address': 'Adresse',
		'Description': 'Description',
		'Select main image': 'Sélectionnez limage principale',
		'Brand name': 'Marque',
		'Phone number': 'Numéro de téléphone',
		'Location': 'Localisation',
		'Enter address': 'Entrer ladresse',
		'Social nets': 'Résaux sociaux',
		'Always open': 'Toujours ouvert',
		'From': 'de',
		'To': 'à',
		'Opened Days': 'Jours ouverts',
		'Closed Days': 'Jours fermés',
		'Drop files here': 'Déposez les fichiers ici',
		'Browse files': 'Parcourir les fichiers',

		//Search
		'Search': 'Chercher',
		'Search by name': 'Rechercher par nom',
		'Search by title': 'Chercher par titre',
		'Search in role': 'Rechercher dans le rôle',

		//Table Row Header
		'Store list': 'Liste de magasin',
		'Name': 'Nom',
		'Contact Name': 'Nom du contact',
		'Position': 'emplacement',
		'Status': 'Statut',
		'Actions': 'Action',
		'No data found': 'Aucune donnée trouvée',
		'PADs list': 'Liste de PADs ',
		'Title': 'Titre',
		'Type': 'Type',
		'Start': 'Commencer',
		'End': 'Terminer',
		'Store': 'Magasin',
		'Regions list': 'Liste des régions',
		'Beacons list': 'Liste des balises',
		'System user list': 'Lista de usuarios del sistema',
		'Role': 'Fonction',
		'Date': 'Date',

		// Days
		'Mon': 'Lun',
		'Tue': 'Ma',
		'Wed': 'Mer',
		'Thur': 'Jeu',
		'Fri': 'Ven',
		'Sat': 'Sam',
		'Sun': 'Dim',

		//Buttons
		'Add': 'Ajouter',
		'Cancel': 'Annuler',
		'Save': 'sauvegarder',
		'Back': 'Back',
		'Add PAD': 'Ajouter un PAD',
		'Add Event': 'Ajouter un évènement',
		'Add News': 'Ajouter des nouvelles',
		'Add Deal': 'Ajouter une offre',
		'Close': 'Fermer',
		'Save changes': 'Enregistrer les modifications',
		'Approve': 'Approve',
		'Reject': 'Reject',
		'Approve all': 'Tout approuver',
		'Reject all': 'Tout rejeter',
		'Sign Out': 'Déconnexion',

		//Stores
		'Store name': 'Nom du magasin',
		'Store type': 'Type de magasin',
		'Contact Person': 'Personne de contact',
		'Created': 'Created',
		'Store delete': 'Supprimer le  magasin',
		'Permanently delete store': 'Êtes-vous sûr de vouloir supprimer définitivement ce magasin?',
		'Store being delete': 'Le magasin va être supprimé',
		'Store has deleted': 'Le magasin a été supprimé',

		//PADs
		'PAD Info': 'PAD Info',
		'Translate': 'Traduire',
		'PAD Date': 'Date PAD',
		'Publication Date': 'Date de publication',
		'Link with store': 'Lien avec le magasin',
		'Select Store': 'Sélectionnez magasin',
		'No record found': 'Aucun enregistrement trouvé',
		'Select timezone': 'Sélectionnez le fuseau horaire',
		'Local': 'Local',
		'UTC': 'UTC',
		'Notification countries': 'Pays de notification',
		'Countries': 'Pays',
		'All Countries': 'Tous les pays',
		'Specific Countries': 'Pays spécifiques',
		'Select': 'Select',
		'Images': 'Images',
		'Exclude': 'Exclure',
		'Select Countries': 'Sélectionnez les pays',
		'Locations': 'Emplacements',
		'Pad delete': 'Effacer PAD',
		'Permanently delete pad': 'Êtes-vous sûr de vouloir supprimer définitivement ce PAD?',
		'PAD being delete': 'Le PAD est en train de se supprimer ...',
		'PAD has deleted': 'Le PAD a été supprimé',

		//Messages
		'No beacons available': 'Aucune balise disponible',
		'No locations available': 'Aucun emplacement disponible',
		'No images for approval': 'No images for approval',
		'Loading please wait.': 'Chargement en cours, veuillez patienter.',
		// 'No result found': 'No results found',
		'Enter valid hours': 'Veuillez entrer des heures douverture valides.',
		'Enter required field': 'Sil vous plaît entrer les champs obligatoires',
		'Required email': 'Sil vous plaît entrer un email valide',
		'Select store type': 'Veuillez sélectionner le type de magasin.',
		'Required information': 'Sil vous plaît entrer les informations requises.',
		'Publication pad date': 'La date du PAD doit être identique ou se situer entre la date de publication',
		'Total image size': 'La taille totale de limage par téléchargement devrait être de 10 Mo',
		'Total 5 images': '5 images sont autorisées par téléchargement',
		'Enter region name': 'Veuillez entrer le nom de votre région.',
		'Alphabets and space': 'Seuls les alphabets et lespace sont autorisés.',
		'Enter beacon name': 'Veuillez entrer le nom de la balise.',
		'Enter role name': 'Please enter role name.',
		'Enter first name': 'Please enter your first name.',
		'Enter last name': 'Please enter your last name.',
		'Enter your mail': 'Please enter your email.',
		'Enter only number': 'Please enter only numbers',

		//Regions & Beacons
		'Region Info': 'Informations sur la région',
		'Region Name': 'Nom de la région',
		'Beacon Info': 'Info de la balise',
		'Beacon Name': 'Nom de la balise',
		'UUID': 'UUID',
		'Major': 'Majeur',
		'Minor': 'Mineur',
		'Region delete': 'Supprimer la Région',
		'Permanently delete Region': 'Êtes-vous sûr de vouloir supprimer définitivement cette région?',
		'Region being delete': 'La région est en train d´ètre supprimer ...',
		'Region has deleted': 'La région a été supprimée',
		'Beacon delete': 'Supprimer la Balise',
		'Permanently delete Beacon': 'Êtes-vous sûr de vouloir supprimer définitivement cette balise?',
		'Beacon being delete': 'Beacon is being deleting...',
		'Beacon has deleted': 'Beacon has been deleted',

		// Users & Roles
		'User Info' : 'Informations dutilisateur',
		'First Name': 'Prénom',
		'Last Name': 'Nom',
		'Select role': 'Sélectionnez le rôle',
		'Password': 'Mot de passe',
		'Confirm Password': 'Confirmez le mot de passe',
		'Authentication': 'Authentification',
		'Created By': 'Créé par',
		'Role Info': 'Info de la fonction',
		'Role Name': 'Nom de la fonction',
		'Permissions': 'Permis',
		'Edit': 'Edit',
		'View': 'View',
		'Delete': 'Delete',
		'Current Password': 'Mot de passe actuel',
		'New Password': 'Nouveau mot de passe',
		'Hi': 'Salut',
		'Role delete': 'Role Delete',
		'Permanently delete role': 'Are you sure to permanently delete this role?',
		'Role being delete': 'Role is being deleting...',
		'Role has deleted': 'Role has been deleted',
		'User delete': 'User Delete',
		'Permanently delete user': 'Are you sure to permanently delete this role?',
		'User being delete': 'User is being deleting...',
		'User has deleted': 'User has been deleted',

		// Billing
		'BILLING': 'Facturation',
		'We appreciate your business': 'We appreciate your business',
		'Thanks for being a customer. A detailed summary': 'Thanks for being a customer. A detailed summary',
		'of your invoice is below.': 'of your invoice is below.',
		'If you have questions, we\'re happy to help.': 'If you have questions, we\'re happy to help.',
		'Email support@sevenjars.com or contact us': 'Email support@sevenjars.com or contact us',
		'through other support channels.': 'through other support channels.',
		'Your Plan': 'Your Plan',
		'Plan' : 'Plan',
		'Next Invoice': 'Facture suivante',
		'Trial Ends': 'Fin dessai',
		'Invoice History': 'Invoice History',
		'Pay': 'Pay',

		//Public Pages
		PUBLIC: {
			TITLE: 'Bienvenue à NearGreen!',
			DESCR: 'Grow your business using our Proximity Marketing technology',
			PRIVACY: 'Privacy',
			TERMS: 'termes',
			SIGNIN: 'Se connecter',
			CONTACT: 'Contact'
		}
	}
};
