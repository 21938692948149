import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User, Permission, Role, AppResponse, Pads ,Products} from '../_models';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import * as sha1 from 'js-sha1';


const API_USERS_URL = 'admin/user/detail';
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
@Injectable()
export class AuthService {
    URL: any = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getIP(){
        let finalURL = this.URL + "/auth/get/ip";
        return this.http.get<AppResponse>(finalURL);
    }

    deviceRegister(ipAddress) {
        let finalURL = this.URL + "/auth/device/register";
        let sendParam = {
            "api_key": environment.apiKey,
            "device_id": ipAddress,
            "identifier": ipAddress,
            "platform": "web",
            "app_type": "admin",
            "app_version": environment.appVersion,
            "device_name": window.navigator.userAgent,
            "timezone": "Asia/Kolkata",
            "country_code":"IN"
        };
        return this.http.post<AppResponse>(finalURL,sendParam);
    }

    // Authentication/Authorization
    login(email: string, password: string): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/login';
        return this.http.post<AppResponse>(finalURL, { email: email, password: password });
    }

    dropDownList(type:string, companyId: string): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/drop/down';
        let params = new HttpParams().set('type', type).set('companyId', companyId);
        return this.http.get<AppResponse>(finalURL, { params: params });
    }

    dropDownListSearch(type:string, srchText: string, strFlg: string): Observable<AppResponse> {
        //alert(type+""+srchText)
        let finalURL = this.URL + '/admin/drop/down';
        let listOfStore = this.http.get<AppResponse>(finalURL, { params: new HttpParams().set('type', type).set('searchTxt', srchText).set('storeFlag', strFlg) });
        return listOfStore;
    }


    dropDownCatList(type:string): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/drop/down';
        let params = new HttpParams().set('type', type);
        return this.http.get<AppResponse>(finalURL, { params: params });
    }

    dropDownCatListSearch(type:string, srchText: string): Observable<AppResponse> {
        //alert(type+""+srchText)
        let finalURL = this.URL + '/admin/drop/down';
        let listOfStore = this.http.get<AppResponse>(finalURL, { params: new HttpParams().set('type', type).set('searchTxt', srchText)});
        return listOfStore;
    }

    getMallInfo(): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/brand/detail';
        return this.http.post<AppResponse>(finalURL,{}) ;
    }

    updateMallInfo(postParam): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/brand/profile/update';
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    updateMallOpenCloseDays(postParam): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/brand/dayson/update';
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    addEditPad(postParam, endPoint: string): Observable<AppResponse> {
        let finalURL = this.URL + endPoint;
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    getPadById(padId): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/get/pad';
        return this.http.get<Pads>(finalURL, { params: new HttpParams().set('padId', padId) });
    }

    addEditProduct(postParam, endPoint: string): Observable<AppResponse> {
        let finalURL = this.URL + endPoint;
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    getArById(arId): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/arvideo/detail';
        return this.http.get<AppResponse>(finalURL, { params: new HttpParams().set('arId', arId) });
    }

    addEditArvideos(postParam, endPoint: string): Observable<AppResponse> {
        let finalURL = this.URL + endPoint;
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    getProductById(productId): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/get/product';
        return this.http.get<Products>(finalURL, { params: new HttpParams().set('productId', productId) });
    }

    apiCall(postParam: object, endPoint: string): Observable<AppResponse> {
        let finalURL = this.URL + endPoint;
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    getStoreInfo(storeId: string): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/store/detail';
        return this.http.post<AppResponse>(finalURL,{ storeId: storeId }) ;
    }

    updateStoreInfo(postParam): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/store/profile/update';
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    updateStoreOpenCloseDays(postParam): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/store/dayson/update';
        return this.http.post<AppResponse>(finalURL, postParam) ;
    }

    getUserByToken(): Observable<AppResponse> {
        const userId = localStorage.getItem("userId");
        let finalURL = this.URL + '/admin/user/detail';
        return this.http.post<AppResponse>(finalURL, { userId: userId }) ;
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        let finalURL = this.URL + '/admin/forgot';
        return this.http.post<AppResponse>(finalURL, { email: email }).pipe(catchError(this.handleError('forgot-password', [])));
    	//return this.http.get(API_USERS_URL + '/forgot?=' + email)
    	//	.pipe(catchError(this.handleError('forgot-password', []))
	    //);
    }


    getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
		return this.http.get<User>(API_USERS_URL + `/${userId}`);
	}


    // DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
    	const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders});
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders});
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
	}

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
