import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Role, RoleListResp } from "../_models";
import { RoleService } from "./role.service";

export class RoleDataSource implements DataSource<Role> {

    private roleSubject = new BehaviorSubject<Role[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private roleService: RoleService) {

    }

    loadRole(filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.roleService.findRoles(filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(roles => {
                //console.log(roles);
                this.resultCount = (roles as RoleListResp).recordsTotal;
                this.totalRecordsSubject.next((roles as RoleListResp).recordsTotal)
                this.roleSubject.next((roles as RoleListResp).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<Role[]> {
        console.log("Connecting data source");
        return this.roleSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.roleSubject.complete();
        this.loadingSubject.complete();
    }

}
