import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Products, ProductsData } from "../_models";
import { ProductService } from "./product.service";


export class ProductsDataSource implements DataSource<ProductsData> {

    private productsSubject = new BehaviorSubject<ProductsData[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;
    public hideAdd: boolean = true;

    constructor(private ProductService: ProductService) {

    }

    loadProducts(productId:string, filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.ProductService.findProducts(productId, filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(products => {
                console.log(products);
                this.hideAdd = (products as Products).hideAdd;
                this.resultCount = (products as Products).recordsTotal;
                this.totalRecordsSubject.next((products as Products).recordsTotal);
                this.productsSubject.next((products as Products).data);
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<ProductsData[]> {
        return this.productsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.productsSubject.complete();
        this.loadingSubject.complete();
    }

}
