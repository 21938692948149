import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Company, CompanyListResp } from "../_models";
import { CompanyService } from "./company.service";

export class CompanyDataSource implements DataSource<Company> {

    private companySubject = new BehaviorSubject<Company[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private companyService: CompanyService) {

    }

    loadCompanies(companyType:string, filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.companyService.findCompanies(companyType, filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(companies => {
                this.resultCount = (companies as CompanyListResp).recordsTotal;
                this.totalRecordsSubject.next((companies as CompanyListResp).recordsTotal)
                this.companySubject.next((companies as CompanyListResp).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<Company[]> {
        console.log("Connecting data source");
        return this.companySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.companySubject.complete();
        this.loadingSubject.complete();
    }

}
