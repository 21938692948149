export class ArvideosData {
  id: string;
  title:string;
  videoName: string;
  videoImages: any;
  arImages:any;


  clear(currentDate: any): void {
      this.id = '';
      this.title = '';
      this.videoName = '';
      this.videoImages = [];
      this.arImages = [];
  }
};

export class Arvideos {
  ts: number;
  success: boolean;
  error: boolean;
  data: ArvideosData[];
  recordsTotal: number;
  hideAdd: boolean;
};
