import { BaseModel } from '../../_base/crud';

export class Role extends BaseModel {
    id: string;
    roleName: string;
    roleKey: string;
    roleType: string;
    nonEditable: number;
    permissions: any;

    title: string;
    isCoreRole: boolean = false;

    clear(): void {
        this.id = '';
        this.roleName = '';
        this.roleKey = '';
        this.roleType = '';
        this.nonEditable = 0;

        this.title = '';
        this.permissions = {
            "brand": { label: "Brand", items: { "view": { label: "View", value: true }, "edit": { label: "Edit", value: true } } },
            "pads": { label: "PADs", items: { "view": { label: "View", value: true }, "add": { label: "Add", value: true }, "edit": { label: "Edit", value: true }, "delete": { label: "Delete", value: true } } },
            "store": { label: "Store", items: { "view": { label: "View", value: true }, "add": { label: "Add", value: true }, "edit": { label: "Edit", value: true }, "delete": { label: "Delete", value: true } } },
            "sysuser": { label: "System User", items: { "view": { label: "View", value: true }, "add": { label: "Add", value: true }, "edit": { label: "Edit", value: true }, "delete": { label: "Delete", value: true } } },
            "roles": { label: "Roles & Permission", items: { "view": { label: "View", value: true }, "add": { label: "Add", value: true }, "edit": { label: "Edit", value: true }, "delete": { label: "Delete", value: true } } },
            "billing": { label: "Billing", items: { "view": { label: "View", value: true }, "edit": { label: "Edit", value: true } } }
        };
        this.isCoreRole = false;
	}
}

export class RoleListResp {
  ts: number;
  success: boolean;
  error: boolean;
  data: Role[];
  recordsTotal: number;
};
