import { Action } from '@ngrx/store';
import { User, SystemUser, Role } from '../_models';

export enum AuthActionTypes {
    DeviceRegister = '[DeviceRegister] Action',
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    SystemUserEdit = '[Edit SystemUser] Action',
    RoleEdit = '[Edit Role] Action'
}

export class DeviceRegister implements Action {
    readonly type = AuthActionTypes.DeviceRegister;
    constructor(public payload: { authToken: string }) { }
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { authToken: string }) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: { authToken: string }) { }
}

export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { }
}

export class SystemUserEdit implements Action {
    readonly type = AuthActionTypes.SystemUserEdit;
    constructor(public payload: { systemUser: SystemUser }) { }
}

export class RoleEdit implements Action {
    readonly type = AuthActionTypes.RoleEdit;
    constructor(public payload: { role: Role }) { }
}

export type AuthActions = Login | Logout | Register | UserRequested | UserLoaded | DeviceRegister | SystemUserEdit | RoleEdit;
