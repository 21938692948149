import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Course, AppResponse } from "../_models";
import { environment } from '../../../../environments/environment';

@Injectable()
export class CoursesService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findCourseById(courseId: number): Observable<Course> {
        return this.http.get<Course>(`/api/courses/${courseId}`);
    }

    findCourses(courseId:number, filter = '', sortOrder = 'asc', sortField= 'company_name', pageNumber = 0, pageSize = 3): Observable<Course> {
        let finalURL = this.URL + '/admin/course/list';
        return this.http.get<Course>(finalURL, {
            params: new HttpParams()
                .set('search', filter)
                .set('order', sortOrder)
                .set('sort', sortField)
                .set('page', pageNumber.toString())
                .set('limit', pageSize.toString())
        }).pipe(
            map(res =>  res)
        );
    }

    /*findAllCourses(): Observable<Course[]> {
        return this.http.get('/api/courses')
            .pipe(
                map(res => res['payload'])
            );
    }

    findAllCourseLessons(courseId:number): Observable<Lesson[]> {
        return this.http.get('/api/lessons', {
            params: new HttpParams()
                .set('courseId', courseId.toString())
                .set('pageNumber', "0")
                .set('pageSize', "1000")
        }).pipe(
            map(res =>  res["payload"])
        );
    }

    findLessons(
        courseId:number, filter = '', sortOrder = 'asc',
        pageNumber = 0, pageSize = 3):  Observable<Lesson[]> {

        return this.http.get('/api/lessons', {
            params: new HttpParams()
                .set('courseId', courseId.toString())
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('pageNumber', pageNumber.toString())
                .set('pageSize', pageSize.toString())
        }).pipe(
            map(res =>  res["payload"])
        );
    }*/

}
