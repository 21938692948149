// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
import * as sha1 from 'js-sha1';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { CustomValidators, ConfirmValidParentMatcher, regExps, errorMessages } from '../../../custom-validation';

@Component({
  selector: 'app-rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RestPasswordComponent implements OnInit {
    // Public params
	resetPasswordForm: FormGroup;
    private unsubscribe: Subject<any>;

    pageTitle: string;
    msgHide: boolean = false;
    nHide: boolean = true;
    confirmValidParentMatcher = new ConfirmValidParentMatcher();
    errors = errorMessages;
    verifyId: string;
    message: string = "loading please wait...";
    loading:any;

    constructor(
        private authService: AuthService,
        public authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef)
    {
        this.pageTitle = this.translate.instant('AUTH.RESET.TITLE');
        this.unsubscribe = new Subject();
        this.verifyId = this.route.snapshot.paramMap.get('verifyID');

        this.authService.getIP().pipe(tap(ipResp => {
			this.authService.deviceRegister(ipResp.data.ip_address).pipe(tap(devResp => {
				localStorage.setItem('accessToken', devResp.data.access_token);

                this.authService.apiCall({ verifyId: this.verifyId }, '/admin/reset/verify').pipe(tap(apiResp => {
                    if(apiResp.data.success){
                        this.msgHide = true;
                    }
                    else {
                        this.pageTitle = this.translate.instant('AUTH.RESET.EXPIRE');
                        this.message = "Reset link expired. Please try again.";
                    }

                    this.cdr.detectChanges();
                },
                error => {
                    console.log(error);
                })).subscribe();
            },
            error => {
                console.log(error);
            })).subscribe();
        },
        error => {
            console.log(error);
        })).subscribe();

    }

    ngOnInit() {
        this.initRegistrationForm();
    }

    initRegistrationForm() {
		this.resetPasswordForm = this.fb.group({
			newPassword: ['', [Validators.required, Validators.pattern(regExps.password)] ],
			confirmPassword: ['', Validators.required]
		}, { validator: CustomValidators.childrenEqual});
	}

    submit() {
        if (this.resetPasswordForm.invalid) {
            return;
        }

        //console.log(this.resetPasswordForm.value);

        let sendParam = {
            verifyId: this.verifyId,
            newPassword: sha1(this.resetPasswordForm.value['newPassword'])
        };

        console.log(sendParam);
        this.authService.apiCall(sendParam, '/admin/reset/password').pipe(tap(apiResp => {
            this.authNoticeService.setNotice(this.translate.instant('AUTH.FORGOT.RESET'), 'success');
            this.router.navigateByUrl('/auth/login');
        },
        error => {
            this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.NOT_FOUND', { name: error.error.error_msg }), 'danger');
        })).subscribe();

    }
}
