// Spain
export const locale = {
	lang: 'es',
	data: {
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			/*NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			PAGES: 'Pages',
			FEATURES: 'Caracteristicas',
			APPS: 'Aplicaciones',*/
			DASHBOARD: 'Panel de control',
			BRANDINFO: 'Información de la marca',
			STOREINFO: 'Lista de tiendas',
			STORES: 'Tiendas',
			PADS: 'PADs',
			REGIONS: 'Regiones',
			BEACONS: 'Balizas',
			ARVIDEOS:'AR Videos',
			APPROVEIMAGES: 'Aprobar imagenes',
			SYSTEMUSER: 'Usuarios del sistema',
			USERS: 'Usuarios',
			BLACKLIST: 'Lista negra',
			ROLES: 'Roles y Permiso',
			ACCOUNT: 'Administrar cuenta',
			MYACCOUNT: 'Mi cuenta',
			CHANGEPASSWORD: 'Cambiar contraseña',
			BILLING: 'Facturación'
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Mot de passe oublié?',
				BACK_BUTTON: 'Espalda',
				PRIVACY: 'Intimidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Crear una cuenta',
				BUTTON: 'Conectarse',
			},
			FORGOT: {
				TITLE: 'Contraseña olvidada?',
				DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
				SUCCESS: 'Tu cuenta ha sido restablecida con éxito'
			},
			REGISTER: {
				TITLE: 'Regístrate',
				DESC: 'Ingresa tus datos para crear tu cuenta',
				SUCCESS: 'Tu cuenta ha sido registrada exitosamente'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Nombre completo',
				PASSWORD: 'Mot de passe',
				CONFIRM_PASSWORD: 'Confirmar contraseña',
				USERNAME: 'Usuario'
			},
			VALIDATION: {
				INVALID: '{{name}} No es válido',
				REQUIRED: '{{name}} Es requerido',
				MIN_LENGTH: '{{name}} La longitud mínima del campo es {{min}}',
				AGREEMENT_REQUIRED: 'Se requieren términos y condiciones de aceptación.',
				NOT_FOUND: 'Lo solicitado {{name}} No ha sido encontrado',
				INVALID_LOGIN: 'Los datos de inicio de sesión son incorrectos',
				REQUIRED_FIELD: 'Campo requerido',
				MIN_LENGTH_FIELD: 'Longitud mínima del campo:',
				MAX_LENGTH_FIELD: 'Longitud máxima del campo:',
				INVALID_FIELD: 'El campo no es valido',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Los registros seleccionados cuentan:',
				ALL: 'Todo',
				SUSPENDED: 'Suspendido',
				ACTIVE: 'Activo',
				FILTER: 'Filtro',
				BY_STATUS: 'por estado',
				BY_TYPE: 'por tipo',
				BUSINESS: 'negocios',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Buscar',
				IN_ALL_FIELDS: 'en todos los campos'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Clientes',
				CUSTOMERS_LIST: 'Lista de clientes',
				NEW_CUSTOMER: 'Cliente nuevo',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Borrar cliente',
					DESCRIPTION: '¿Estás seguro que quieres borrar este Cliente permanentemente?',
					WAIT_DESCRIPTION: 'El cliente está siendo borrado...',
					MESSAGE: 'El cliente ha sido borrado'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Borrar cliente',
					DESCRIPTION: '¿Estás seguro que quieres borrar este Cliente permanentemente?',
					WAIT_DESCRIPTION: 'El cliente está siendo borrado...',
					MESSAGE: 'El cliente ha sido borrado'
				},
				UPDATE_STATUS: {
					TITLE: 'El estado ha sido actualizado para clientes seleccionados',
					MESSAGE: 'Los estados de los clientes seleccionados se han actualizado con éxito'
				},
				EDIT: {
					UPDATE_MESSAGE: 'El cliente ha sido actualizado',
					ADD_MESSAGE: 'El cliente ha sido creado'
				}
			}
		},
		ALERT: {
			TITLE: {
				SUCCESS: 'Success',
				ERROR: 'Error'
			},
			MESSAGE: {
				INFO_ADD_SUCCESS: 'Information added successfully.',
				INFO_UPDATE_SUCCESS: 'Information updated successfully.',
				OPENDAY_UPDATE_SUCCESS: 'Opened days information updated successfully.',
				CLOSEDAY_UPDATE_SUCCESS: 'Closed days information updated successfully.',
				IMAGE_UPDATE_SUCCESS: 'Image(s) added successfully.',
				IMAGE_REMOVE_SUCCESS: 'Image(s) removed successfully.',
				IMAGE_APPROVE_SUCCESS: 'Image(s) approved successfully.',
				IMAGE_REJECTED_SUCCESS: 'Image(s) rejected successfully.',
				MALL: {
					DELETE_MESSAGE: 'Deleted '
				},
				STORE: {
					DELETE_LIST_MESSAGE: 'Store removed successfully.'
				},
				PAD: {
					DELETE_LIST_MESSAGE: 'PAD removed successfully.'
				},
				REGION: {
					DELETE_LIST_MESSAGE: 'Region removed successfully.'
				},
				BEACON: {
					DELETE_LIST_MESSAGE: 'Beacon removed successfully.'
				},
				PROFILE: {
					PASSWORD_MESSAGE: 'Password updated successfully.',
					PROFILE_MESSAGE: 'Profile updated successfully.'
				},
				SYSTEM_USER: {
					CREATED: 'User added successfully.',
					UPDATED: 'User details updated successfully.',
					DELETE_LIST_MESSAGE: 'User blacklisted successfully.',
					DELETE_LIST_CONFIRM: 'User removed successfully.'
				},
				ROLE: {
					CREATED: 'Role added successfully.',
					UPDATED: 'Role updated successfully.',
					DELETE_LIST_MESSAGE: 'Role removed successfully.'
				}
			}
		},
		'Dashboard': 'Panel de control',
		'Brand Info': 'Información de la marca',
		'Brand': 'Información de la marca',
		'Store Info': 'Lista de tiendas',
		'Stores': 'Tiendas',
		'PADs': 'PADs',
		'Regions': 'Regiones',
		'Beacons': 'Balizas',
		'AR Videos': 'AR Videos',
		'Approve Images': 'Aprobar imagenes',
		'System Users': 'Usuarios del sistema',
		'System User': 'Usuarios del sistemar',
		'Users': 'Usuarios',
		'Blacklist': 'Lista negra',
		'Roles & Permission': 'Roles y Permiso',
		'Manage Account': 'Administrar cuenta',
		'My Account': 'Mi cuenta',
		'Change Password': 'Cambiar contraseña',
		'Billing': 'Facturación',
		'Image Approval': 'Approbation de l\'image',
		'Add Store': 'Añadir tienda',
		'View Store': 'View Store',
		'Create Event': 'Crear evento',
		'Create News': 'Crear noticias',
		'Create Deal': 'Crear una oferta',
		'Edit PAD' : 'Editar PAD',
		'Add Region': 'Añadir región',
		'Edit Region': 'Editar región',
		'Add Beacon': 'Añadir baliza',
		'Edit Beacon': 'Editar baliza',
		'Add ARVideos':'Añadir ARVideos',
		'Add System Users': 'Añadir usuarios del sistema',
		'Add Role': 'Añadir una función',
		'Edit Role': 'Editar Rol',
		'Beacon': 'la baliza',
		'Add User': 'Agregar usuario',

		//BreadCrumb Description
		'Latest updates and statistic charts': 'Últimas actualizaciones y cuadros estadísticos.',
		'All the information about your brand': 'Toda la información sobre tu marca.',
		'All the information about stores': 'Toda la información sobre tiendas',
		'All the information about store': 'Toda la información sobre tienda',
		'Your proximity advertisments': 'Sus anuncios de proximidad',
		'Your store fence': 'Clausura de tu tienda',
		'Your proximity': 'Tu proximidad',
		'All the information about arvideos': 'Toda la información sobre arvideos',
		'approve images uploaded': 'Aprobar imágenes subidas',
		'all the users of your system': 'todos los usuarios de tu sistema',
		'deleted users of your system': 'Usuarios eliminados de su sistema',
		'roles of your system': 'roles de su sistema',
		'add role to your system': 'Añadir una función a tu sistema',
		'edit permission of the role': 'edit permission of the role',
		'update your profile.': 'Actualiza tu perfil',
		'update your password.': 'actualiza tu contraseña',
		'All the information about your billing': 'Toda la información sobre su facturación.',

		//DASHBOARD
		'Stores Registered': 'Tiendas Registradas',
		'Total Beacons Installed': 'Número de total de balizas instaladas',
		'PADs Delivered': 'PADs entregados',
		'Visitors': 'Visitantes',

		//Brand Info
		'Overview': 'Visión general',
		'Edit Profile': 'Editar perfil',
		'Opened / Closed on': 'Abierto / Cerrado',
		'Photos': 'Fotos',
		'Contact Information': 'Información del contacto',
		'Phone': 'Teléfono',
		'Email': 'Email',
		'Website': 'Sitio web',
		'Opening hours': 'Horario de apertura',
		'Timezone': 'Zona horaria',
		'Address': 'Dirección',
		'Description': 'Descripción',
		'Select main image': 'Seleccionar imagen principal',
		'Brand name': 'Marca',
		'Phone number': 'Número de teléfono',
		'Location': 'Ubicación',
		'Enter address': 'Ingresa la direccion',
		'Social nets': 'Redes sociales',
		'Always open': 'Siempre abierto',
		'From': 'Desde',
		'To': 'a',
		'Opened Days': 'Días abiertos',
		'Closed Days': 'Días cerrados',
		'Drop files here': 'Soltar archivos aquí',
		'Browse files': 'Búsqueda de archivos',

		//Search
		'Search': 'Buscar',
		'Search by name': 'Buscar por nombre',
		'Search by title': 'Buscar por título',
		'Search in role': 'Buscar en rol',

		//Table Row Header
		'Store list': 'Lista de tiendas',
		'Name': 'Nombre',
		'Contact Name': 'Nombre de contacto',
		'Position': 'Localización',
		'Status': 'Estado',
		'Actions': 'Acción',
		'No data found': 'No se ha encontrado la información',
		'PADs list': 'Lista de PADs',
		'Title': 'Título',
		'Type': 'Tipo',
		'Start': 'Empezar',
		'End': 'Terminar',
		'Store': 'Tienda',
		'Regions list': 'Lista de regiones',
		'Beacons list': 'Lista de balizas',
		'ARVideos list': 'Lista de videos',
		'System user list': 'Liste d\'utilisateurs du système',
		'Role': 'Función',
		'Date': 'Fecha',

		// Days
		'Mon': 'Lun',
		'Tue': 'Mar',
		'Wed': 'Mier',
		'Thur': 'Jue',
		'Fri': 'Vie',
		'Sat': 'Sab',
		'Sun': 'Dom',

		//Buttons
		'Add': 'Añadir',
		'Cancel': 'Cancelar',
		'Save': 'Guardar',
		'Back': 'atrás',
		'Add PAD': 'Añadir PAD',
		'Add Event': 'Añadir evento',
		'Add News': 'Añadir noticias',
		'Add Deal': 'Añadir oferta',
		'Close': 'Cerrar',
		'Save changes': 'Guardar Cambios',
		'Approve': 'Aprobar',
		'Reject': 'Rechazar',
		'Approve all': 'Aprobar todo',
		'Reject all': 'Rechazar todo',
		'Sign Out': 'desconectar',

		//Stores
		'Store name': 'Nombre de la tienda',
		'ON':'EN',
		'OFF':'APAGADA',
		'Store type': 'Tipo de tienda',
		'Contact Person': 'Persona de contacto',
		'Created': 'Creado',

		'Store delete': 'Borar tienda',
		'Permanently delete store': '¿Estás seguro que quieres borrar esta tienda permanentemente?',
		'Store being delete': 'La tienda está siendo borrada...',
		'Store has deleted': 'La tienda ha sido borrada',

		//PADs
		'PAD Info': 'Info PAD',
		'Translate': 'Traducir',
		'PAD Date': 'Fecha PAD',
		'Publication Date': 'Fecha de publicación',
		'Link with store': 'Enlace con tienda',
		'Select Store': 'Seleccione Tienda',
		'No record found': 'No record found',
		'Select timezone': 'Selecciona la zona horaria',
		'Local': 'Local',
		'UTC': 'UTC',
		'Notification countries': 'Países de notificación',
		'Countries': 'Países',
		'All Countries': 'Todos los países',
		'Specific Countries': 'Paises especificos',
		'Select': 'Seleccione',
		'Images': 'Imágenes',
		'Exclude': 'Excluir',
		'Select Countries': 'Selecciona los paises',
		'Locations': 'Ubicaciones',

		'Pad delete': 'Borrar PAD',
		'Permanently delete pad': '¿Estás seguro que quieres borrar este PAD permanentemente?',
		'PAD being delete': 'El PAD está siendo borrado...',
		'PAD has deleted': 'EL PAD ha sido borrado',

		//Messages
		'No beacons available': 'No hay balizas disponibles',
		'No locations available': 'No hay ubicaciones disponibles',
		'No images for approval': 'No images for approval',
		'Loading please wait.': 'Loading please wait.',
		// 'No result found': 'No results found',
		'Enter valid hours': 'Por favor, introduce un horario de apertura valido',
		'Enter required field': 'Por favor, rellena los campos obligatorios',
		'Required email': 'Por favor, introduce un correo electrónico válido',
		'Select store type': 'Por favor, selecciona el tipo de tienda',
		'Required information': 'Por favor, rellena la información obligatoria',
		'Publication pad date': 'La fecha del PAD debe ser igual o estar entre la fecha de publicación',
		'Total image size': 'El peso total de la imagen debe ser 10MB',
		'Total 5 images': 'El total de imágenes permitidas son 5',
		'Enter region name': 'Por favor, introduce el nombre de la región',
		'Alphabets and space': 'Solo se permite letras y espacios',
		'Enter beacon name': 'Por favor, introduce el nombre del beacon',
		'Enter role name': 'Por favor, introduce el nombre del rol',
		'Enter first name': 'Por favor, introduce tu nombre',
		'Enter last name': 'Por favor, introduce tus apellidos',
		'Enter your mail': 'Por favor, introduce tu correo electrónico',
		'Enter only number': 'Por favor, introduce solo números',

		//Regions
		'Region Info': 'Información de la región',
		'Region Name': 'Nombre de la región',
		'Beacon Info': 'Info de la baliza',
		'Beacon Name': 'Nombre de la baliza',
		'Image & Videos Info':'Información de imagen y videos',
		'Upload images':'Sube imágenes',
		'Upload videos':'Subir videos',
		'UUID': 'UUID',
		'Major': 'Mayor',
		'Minor': 'Menor',

		'Region delete': 'Borrar región',
		'Permanently delete Region': '¿Estás seguro que quieres borrar esta región permanentemente?',
		'Region being delete': 'La región está siendo borrada...',
		'Region has deleted': 'La región ha sido borrada',
		'Beacon delete': 'Borrar Beacon',
		'Permanently delete Beacon': '¿Estás seguro que quieres borrar este Beacon permanentemente?',
		'Beacon being delete': 'El Beacon está siendo borrado...',
		'Beacon has deleted': 'EL Beacon ha sido borrado',

		// Users & Roles
		'User Info' : 'Información de usuario',
		'First Name': 'Nombre',
		'Last Name': 'Apellido',
		'Select role': 'Seleccionar el puesto',
		'Password': 'Contraseña',
		'Confirm Password': 'Confirmar contraseña',
		'Authentication': 'Autenticación',
		'Created By': 'Creado por',
		'Role Info': 'Info de la Función',
		'Role Name': 'Nombre de la función',
		'Permissions': 'Permisos',
		'Edit': 'Editar',
		'View': 'Ver',
		'Delete': 'Borrar',
		'Current Password': 'Contraseña actual',
		'New Password': 'Nueva contraseña',
		'Hi': 'Hola',

		'Role delete': 'Borrar rol',
		'Permanently delete role': '¿Estás seguro que quieres borrar este rol permanentemente?',
		'Role being delete': 'El rol está siendo borrado...',
		'Role has deleted': 'EL rol ha sido borrado',
		'User delete': 'Borrar usuario',
		'Permanently delete user': '¿Estás seguro que quieres borrar este usuario permanentemente?',
		'User being delete': 'El usuario está siendo borrado...',
		'User has deleted': 'EL usuario ha sido borrado',

		// Billing
		'BILLING': 'Facturación',
		'We appreciate your business': 'Valoramos tu negocio',
		'Thanks for being a customer. A detailed summary': 'Gracias por formar parte de Appadia. A continuación encontrarás ',
		'of your invoice is below.': 'los detalles de tu factura.',
		'If you have questions, we\'re happy to help.': 'Tienes alguna pregunta? Estamos encantados de ayudarte',
		'Email support@sevenjars.com or contact us': 'Mándonos un email support@sevenjars.com o contacta con',
		'through other support channels.': 'nosotros a través de nuestras cuentas de Ayuda al Cliente',
		'Your Plan': 'Tu Suscripción',
		'Plan' : 'Plan',
		'Next Invoice': 'Siguiente factura',
		'Trial Ends': 'Trial Ends',
		'Invoice History': 'Historial de facturación',
		'Pay': 'Pagar',

		//Public Pages
		PUBLIC: {
			TITLE: 'Bienvenido a NearGreen!',
			DESCR: 'Haga crecer su negocio con nuestra tecnología de marketing de proximidad.',
			PRIVACY: 'Privacidad',
			TERMS: 'Condiciones',
			SIGNIN: 'Registrarse',
			CONTACT: 'Contacto'
		}
	}
};
