// Angular
import { Component, OnInit, ViewEncapsulation, HostBinding } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService } from '../../../core/_base/layout';
// Metronic
import { TranslationService } from '../../../core/_base/metronic';
// Auth
import { AuthNoticeService } from '../../../core/auth';
import { environment } from '../../../../environments/environment';

import { filter } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

	@HostBinding('class') classes = '';

	language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/012-uk.svg'
		},
		{
			lang: 'es',
			name: 'Spanish',
			flag: './assets/media/flags/016-spain.svg'
		}/*,
		{
			lang: 'de',
			name: 'German',
			flag: './assets/media/flags/017-germany.svg'
		},
		{
			lang: 'fr',
			name: 'French',
			flag: './assets/media/flags/019-france.svg'
		},*/
	];

	// Public properties
	today: number = Date.now();
	headerLogo: string;
	appName: string;
	publicLink: string;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		private router: Router) {

			this.appName = environment.appName;
			this.publicLink = environment.publicLink;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.setSelectedLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});

		this.translationService.setLanguage(this.translationService.getSelectedLanguage());
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.hide();
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		this.translationService.setLanguage(lang);
	}

	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}

}
