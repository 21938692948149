export class ProductsData {
  id: string;
  productName: string;
  category: string;
  categories:string;
  description: string;
  categoryName:string;
  productImages: any;
  categoryId:string;
  categoryDet:any;

  clear(currentDate: any): void {
      this.id = '';
      this.productName = '';
      this.category = '';
      this.categories = '';
      this.description = '';
      this.categoryName ='';
      this.productImages = [];
      this.categoryId = '';
      this.categoryDet = { id: '', categories: '' };

  }
};

export class Products {
  ts: number;
  success: boolean;
  error: boolean;
  data: ProductsData[];
  recordsTotal: number;
  hideAdd: boolean;
};
