// Angular
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';

@Component({
	selector: 'kt-subheader1',
	templateUrl: './subheader1.component.html',
	styleUrls: ['./subheader1.component.scss']
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
	// Public properties
	today: number = Date.now();
	title: string = '';
	transTitle: string = '';
	desc: string = '';
	breadcrumbs: Breadcrumb[] = [];
	btnHidden: boolean = true;

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(public subheaderService: SubheaderService,
			private _location: Location,
		   	private router: Router,
			private translate: TranslateService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		/*if(this.router.url.indexOf('/stores/view/') > -1){
			this.btnHidden = true;
		}

		if(this.router.url.indexOf('/stores/view/') > -1){
			this.btnHidden = true;
		}*/
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
			// breadcrumbs title sometimes can be undefined
			if (bt) {
				Promise.resolve(null).then(() => {
					console.log(bt);
					this.title = bt.title;
					this.desc = bt.desc;
				});
			}
		}));

		this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
			Promise.resolve(null).then(() => {
				this.breadcrumbs = bc;

				this.btnHidden = true;
				if(this.router.url.indexOf('/stores/view/') > -1 || this.router.url.indexOf('/pads/edit/') > -1){
					this.btnHidden = false;
				}
				if(this.router.url.indexOf('/system-users/edit') > -1 || this.router.url.indexOf('/roles/edit') > -1){
					this.btnHidden = false;
				}
				if(this.router.url.indexOf('/regions/edit/') > -1 || this.router.url.indexOf('/beacons/edit/') > -1){
					this.btnHidden = false;
				}
			});
		}));
	}

	backClicked() {
    	this._location.back();
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}
}
