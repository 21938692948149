import { FormGroup, FormControl, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

/**
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {
	/**
	 * Validates that child controls in the form group are equal
	 */
	static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
		const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {});
		const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === formGroup.get(firstControlName).value);
		return isValid ? null : { childrenNotEqual: true };
	}
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		return control.parent.invalid && control.touched;
	}
}

/**
* Collection of reusable RegExps
*/
export const regExps: { [key: string]: RegExp } = {
    //password: /\A(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9]{7,15}\z$/
    //password: /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{7,15})$/
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
};

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
	currentPassword: 'Current password is required',
	newPassword: 'Password must be between 7 and 15 characters, and contain at least one number and one uppercase character',
	confirmPassword: 'Password must match'
};
