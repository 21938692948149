// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    appVersion: '1.0.0',
    // authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    // //apiUrl: 'http://localhost:1337/v1',
    // apiUrl: 'https://devhsapi.nanonino.es/v1',
    // apiKey: '6c1639cdb3604a8db02801a9f3c00914', //'228eb6da194a65e1a05fe762c3c501c1',
    // apiSecret: 'QQS9YRVkexWrRfdhkdAy84LT9PRs4UzCqMc5ShPV', //'hbDe9jLhd37e5U5zUKXp38zNfYhgUCNp7uB7PbF8',
    // agmKey: 'AIzaSyCTUzlMyV1N0VRDxcq79RQwSIlT6mp-wyM',
    // appName: "NearGreen",
    // publicLink: "https://neargreen.appadia.com"
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    //apiUrl: 'http://localhost:1337/v1',
    apiUrl:'https://devsjapi.nanonino.es/v1',
    apiKey: '6c1639cdb3604a8db02801a9f3c00914', //'228eb6da194a65e1a05fe762c3c501c1',
    apiSecret: 'QQS9YRVkexWrRfdhkdAy84LT9PRs4UzCqMc5ShPV', //'hbDe9jLhd37e5U5zUKXp38zNfYhgUCNp7uB7PbF8',
    agmKey: 'AIzaSyCTUzlMyV1N0VRDxcq79RQwSIlT6mp-wyM',
    appName: "SevenJars",
    publicLink: ""
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

