import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Regions, AppResponse } from "../_models";
import { environment } from '../../../../environments/environment';

@Injectable()
export class RegionsService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findRegions(filter = '', sortOrder = 'asc', sortField= 'firstName', pageNumber = 0, pageSize = 3): Observable<AppResponse> {
        let finalURL = this.URL + '/admin/regions/list';
        return this.http.get<AppResponse>(finalURL, {
            params: new HttpParams()
                .set('search', filter)
                .set('order', sortOrder)
                .set('sort', sortField)
                .set('page', pageNumber.toString())
                .set('limit', pageSize.toString())
                .set('companyId', localStorage.getItem('companyId'))
        }).pipe(
            map(res =>  res)
        );
    }
}
