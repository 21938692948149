import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export class Billing {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  position: string;
  role: any;
  userType: string;
  lastLogin: string;
  createdAt: string;

  clear() {
      this.id = '';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phoneNo = '';
      this.position = '';
      this.role = { id: '', roleName: '', roleKey: '' };
      this.userType = '';
      this.lastLogin = '';
      this.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
  }
}

export class BillingListResp {
  ts: number;
  success: boolean;
  error: boolean;
  data: Billing[];
  recordsTotal: number;
};
