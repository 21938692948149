import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export class Company {
  id: string;
  type: string;
  companyName: string;
  storeType: string;
  email: string;
  phoneNo: string;
  description: string;
  website: string;
  parking: string;
  cinema: string;
  address: any;
  storeStatus:any;
  alwaysOpen: string;
  amenities: any[];
  categories: any[];
  latitude: string;
  longitude: string;
  openHours: any[];
  openDays: any[];
  closingDays: any[];
  timeZone: string;
  socialNets: any;
  mainImage: string;
  images: any;
  contactPerson: string;
  personPosition: string;
  personEmail: string;
  personPhoneNo: string;
  inviteStatus: string;
  createdAt: string;

  clear() {
      this.id = '';
      this.type = '';
      this.companyName = '';
      this.storeType = '';
      this.email = '';
      this.phoneNo = '';
      this.description = '';
      this.website = '';
      this.parking = '';
      this.cinema = '';
      this.address = { fullAddress: '' };
      this.alwaysOpen = '';
      this.amenities = [];
      this.categories = [];
      this.latitude = '';
      this.longitude = '';
      this.openHours = [];
      this.openDays = [];
      this.closingDays = [];
      this.timeZone = "";
      this.socialNets = { fb: "", tw: "", yt: "", gp: "", inst: "", frsq: "" }
      this.mainImage = "";
      this.images = [];
      this.contactPerson = "";
      this.personPosition = "";
      this.personEmail = "";
      this.personPhoneNo = "";
      this.inviteStatus = "invite";
      this.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
  }
}

export class CompanyListResp {
  ts: number;
  success: boolean;
  error: boolean;
  data: any[];
  recordsTotal: number;
};
