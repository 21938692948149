import { Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Pads } from "../_models/pads.model";
import { environment } from '../../../../environments/environment';

@Injectable()
export class PadService {
    URL: any = environment.apiUrl;

    constructor(private http:HttpClient) {}

    findPADById(courseId: number): Observable<Pads> {
        return this.http.get<Pads>(`/admin/pad/${courseId}`);
    }

    findPADs(padId:number, filter = '', sortOrder = 'asc', sortField= 'status', pageNumber = 0, pageSize = 3): Observable<Pads> {
        let companyType: string = localStorage.getItem('companyType'),
            companyId: string = localStorage.getItem('companyId');

        let params = new HttpParams()
            .set('search', filter)
            .set('order', sortOrder)
            .set('sort', sortField)
            .set('page', pageNumber.toString())
            .set('limit', pageSize.toString());

        if(companyType == 'store'){
            params.set('storeId', companyId);
        }

        let finalURL = this.URL + '/admin/pads/list';
        return this.http.get<Pads>(finalURL, {
            params: params
        }).pipe(
            map(res =>  res)
        );
    }
}
