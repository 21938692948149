import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Course, CoursesData } from "../_models";
import { CoursesService } from "./courses.service";

export class CoursesDataSource implements DataSource<CoursesData> {

    private coursesSubject = new BehaviorSubject<CoursesData[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private coursesService: CoursesService) {

    }

    loadCourses(courseId:number, filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.coursesService.findCourses(courseId, filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(courses => {
                this.resultCount = (courses as Course).recordsTotal;
                this.totalRecordsSubject.next((courses as Course).recordsTotal)
                this.coursesSubject.next((courses as Course).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<CoursesData[]> {
        console.log("Connecting data source");
        return this.coursesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.coursesSubject.complete();
        this.loadingSubject.complete();
    }

}
