import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { SystemUser, SystemUserListResp } from "../_models";
import { SystemUserService } from "./system-user.service";

export class SystemUserDataSource implements DataSource<SystemUser> {

    private systemUserSubject = new BehaviorSubject<SystemUser[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private systemUserService: SystemUserService) {

    }

    loadSystemUser(filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number, status:number) {

        this.loadingSubject.next(true);
        this.systemUserService.findSystemUsers(filter, sortDirection, sortField, pageIndex, pageSize, status).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(systemUsers => {
                //alert(JSON.stringify(systemUsers))
                //console.log(systemUsers);
                this.resultCount = (systemUsers as SystemUserListResp).recordsTotal;
                this.totalRecordsSubject.next((systemUsers as SystemUserListResp).recordsTotal)
                this.systemUserSubject.next((systemUsers as SystemUserListResp).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<SystemUser[]> {
        console.log("Connecting data source");
        return this.systemUserSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.systemUserSubject.complete();
        this.loadingSubject.complete();
    }

}
