// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, filter, debounceTime, switchMap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { select,Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, Company, PadsData, Marker, currentUser } from '../../../../core/auth/';
import { Subject, Observable, of, ReplaySubject, } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';

import { MatIconRegistry, MatSelect } from '@angular/material';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ToastrService } from 'ngx-toastr';
import * as sha1 from 'js-sha1';


//import { MatButtonModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogModule, MatTabsModule, MatNativeDateModule, MatCardModule, MatRadioModule, MatIconModule, MatDatepickerModule, MatExpansionModule, MatAutocompleteModule, MAT_DIALOG_DEFAULT_OPTIONS, MatSnackBarModule, MatTooltipModule, MatButtonToggleModule, MatListModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatSelectModule, MatMenuModule, MatProgressBarModule } from '@angular/material';


@Component({
    selector: 'kt-register',
    templateUrl: './register.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
    loginType: string;
    registerForm: FormGroup;
    loading = false;
    errors: any = [];
    storeList: any;
    storeFilteringCtrl: any;
    public searching: boolean = false;
    sendParam: any;
    ipAddress:string;
    

    @ViewChild("search") searchElementRef: ElementRef;

    public filteredStore: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

    @ViewChild('singleSelect') singleSelect: MatSelect;

    protected _onDestroy = new Subject<void>();

    private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param router: Router
     * @param auth: AuthService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     */
    constructor(
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService
    ) {
        this.device();
        this.unsubscribe = new Subject();

        let companyId = localStorage.getItem('companyId');
        this.loginType = localStorage.getItem('companyType');

        this.registerForm = this.fb.group({
            firstname: ['', Validators.compose([
                Validators.required
            ])
            ],

            lastname: ['', Validators.compose([
                Validators.required
            ])
            ],

            phonenumber: ['', Validators.compose([
                Validators.required
            ])
            ],

            poscmpy: ['', Validators.compose([
                Validators.required
            ])
            ],

            storeCtrl: new FormControl([]),

            storeFilteringCtrl: new FormControl([]),

            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                Validators.maxLength(320)
            ]),
            ],

            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ],

            confirmPassword: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ],

            agree: [false, Validators.compose([Validators.required])]
        }, {
            validator: ConfirmPasswordValidator.MatchPassword
        });

        this.registerForm.get('storeFilteringCtrl').valueChanges
        .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        switchMap(search => this.auth.dropDownListSearch('storeSearch', search, 'signup') )
        )
        .subscribe(filteredStore => {
        this.searching = false;
        this.storeList = filteredStore.data;
        this.filteredStore.next(filteredStore.data);
        },
        error => {
        this.searching = false;
        });
    }

    device(){

        this.auth.getIP().pipe(tap(ipResp => {
			this.ipAddress = ipResp.data.ip_address;
			this.auth.deviceRegister(this.ipAddress).pipe(tap(devResp => {
				localStorage.setItem('accessToken', devResp.data.access_token);
			},
			error => {
				//console.log(error);
			})).subscribe();
		},
		error => {
			//console.log(error);
		})).subscribe();
    };

    /*
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

    /**
     * On init
     */
    ngOnInit() {
        //this.initRegisterForm();
        // alert(this.registerForm.get('storeFilteringCtrl').value)
    }

    private _filterStore(value: string): Company[] {
    const filterValue = value.toLowerCase();
    return this.storeList.filter(store => store.companyName.toLowerCase().indexOf(filterValue) === 0);
    }

    displayFn(store?: Company): string | undefined {
    return store ? store.companyName : undefined;
    }

    /*
 * On destroy
 */
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
        this._onDestroy.next();
        this._onDestroy.complete();
    }


    /**
     * Form Submit
     */
    submit() {
        const controls = this.registerForm.controls;

        // check form
        if (this.registerForm.invalid) {
            Object.keys(controls).forEach(controlName =>
             controls[controlName].markAsTouched()
            );
            return;
        }

        // this.loading = true;

        if (!controls['agree'].value) {
         this.toastr.error("Accept Terms & Conditions", this.translate.instant('ALERT.TITLE.ERROR'));
         return;
        }

        let storeCtrl = this.registerForm.value['storeCtrl'];
        let storeId = (storeCtrl == "") ? "" : ((storeCtrl instanceof Object) ? storeCtrl.id : storeCtrl);

        let sendParam = {
            email: this.registerForm.value['email'],
            firstName: this.registerForm.value['firstname'],
            lastName: this.registerForm.value['lastname'],
            phoneNo: this.registerForm.value['phonenumber'],
            position: this.registerForm.value['poscmpy'],
            storeId: storeId,
            ipAddress: this.ipAddress,
            //password: this.registerForm.value['password'],
            password: sha1(controls['password'].value)

            // sha1(controls['password'].value)
        };
        //console.log(sendParam);
        this.auth.apiCall(sendParam, '/admin/change/signup/store').pipe(tap(signupResp => {
            this.toastr.success(this.translate.instant('ALERT.MESSAGE.INFO_UPDATE_SUCCESS'), this.translate.instant('ALERT.TITLE.SUCCESS'));
            this.router.navigate(['/auth/login']);
        },
        error => {
            this.toastr.error(error.error.error_msg, this.translate.instant('ALERT.TITLE.ERROR'));
        })).subscribe();

    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.registerForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
    // isControlHasError(controlName: string) {
 // let currenForm = this.registerForm.controls;
 // return 'Please enter required information.';
 // }
}