// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User, SystemUser, Role } from '../_models';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    user: User;
    isUserLoaded: boolean;
    systemUser: SystemUser;
    role: Role;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false,
    systemUser: undefined,
    role: undefined
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.Login: {
            const _token: string = action.payload.authToken;
            return {
                ...state,
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Register: {
            const _token: string = action.payload.authToken;
            return {
                ...state,
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Logout:
            return initialAuthState;

        case AuthActionTypes.UserLoaded: {
            const _user: User = action.payload.user;
            return {
                ...state,
                user: _user,
                isUserLoaded: true
            };
        }

        case AuthActionTypes.SystemUserEdit: {
            const _systemUser: SystemUser = action.payload.systemUser;
            return {
                ...state,
                systemUser: _systemUser
            };
        }

        case AuthActionTypes.RoleEdit: {
            const _role: Role = action.payload.role;
            return {
                ...state,
                role: _role
            };
        }

        default:
            return state;
    }
}
