export class Regions {
  id: string;
  regionName: string;
  regionRadius: number;
  location: any;
  latitude: string;
  longitude: string;
  companyId: string;
  companyName: string;

  clear() {
      this.id = '';
      this.regionName = '';
      this.regionRadius = 250;
      this.location = { fullAddress: '' };
      this.latitude = '';
      this.longitude = '';
      this.companyId = '';
      this.companyName = '';
  }
}
