import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    id: number;
    email: string;
    image: string;
    roles: number[];
    firstName: string;
    lastName: string;
    position: string;
	companyName: string;
	phoneNo: string;
    address: Address;
    socialNetworks: SocialNetworks;
    status: number;
    company: any;
    role: any;
    companyId: string;

    clear(): void {
        this.id = undefined;
        this.email = '';
        this.roles = [];
        this.firstName = '';
        this.lastName = '';
        this.image = '/assets/media/users/default.jpg';
        this.position = '';
        this.companyName = '';
        this.phoneNo = '';
        this.company = { id: "", companyName: "", companyType: ""};
        this.role = { id: "", roleName: "", roleKey: "", roleType: "" };
        this.address = new Address();
        this.address.clear();
        this.socialNetworks = new SocialNetworks();
        this.socialNetworks.clear();
        this.companyId = '';
    }
}
