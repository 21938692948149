import { Component, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';
import { NgbTimeStruct, NgbTimeAdapter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AuthService } from '../../../../../core/auth';
import { map, startWith, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { number } from '@amcharts/amcharts4/core';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-share-app',
  templateUrl: './share-app.component.html',
  styleUrls: ['./share-app.component.scss']
})
export class ShareAppComponent implements OnInit {
  rulesForm: FormGroup;
	padlanginfo = { email: "", popupdescription: "", popuppadLink: ""};
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	emailList = [];
	removable = true;
	fb: FormBuilder = new FormBuilder();
  emailes:any = [];
  store:any;
  params:any;
  some:any;

  constructor(private auth: AuthService, private modalService: NgbModal, private toastr:ToastrService, private translate:TranslateService){
		this.rulesForm = this.fb.group({
			emails: this.fb.array([], [this.validateArrayNotEmpty]),
		  });
	}

  ngOnInit() {
    
    
  }

  validateArrayNotEmpty(c: FormControl) {
		if (c.value && c.value.length === 0) {
		  return {
			validateArrayNotEmpty: { valid: false }
		  };
		}
		return null;
    }
    
    
    openLarge(content) {
      this.modalService.open(content, {
        size: number[500]
        });
   }

   add(event): void {
		console.log(event.value)
		if (event.value) {
		  if (this.validateEmail(event.value)) {
			this.emailList.push({ value: event.value, invalid: false });
		  } else {
			this.emailList.push({ value: event.value, invalid: true });
			this.rulesForm.controls['emails'].setErrors({'incorrectEmail': true});
		  }
		}
		if (event.input) {
		  event.input.value = '';
		}
    }
    
    removeEmail(data: any): void {
      console.log('Removing ' + data)
      if (this.emailList.indexOf(data) >= 0) {
        this.emailList.splice(this.emailList.indexOf(data), 1);
      }
      }

      onpopupSubmit():void{
        let userdata = JSON.parse(localStorage.getItem('userdetail'))
        this.store = userdata.company.id;
        if(this.emailList.length == 0 ){
          this.toastr.error('Please enter a email', 'Error');
          return;
        }

      let emailvalues = {};
      let emailcheckvalid = {}; 
      this.emailes = [];
      console.log(this.emailList)
      for(let i=0; i < this.emailList.length; i++){
        if(this.emailList[i].invalid == true){
          this.toastr.error('Please enter a valid email', 'Error');
          return;
        }
      	emailvalues = {
      		"email": this.emailList[i].value
        }
      	this.emailes.push(emailvalues);
      }
      let sendParam = {
        emails: JSON.stringify(this.emailes),
        storeId: this.store
      }
         this.auth.apiCall(sendParam,'/admin/dashboard/share/app').pipe(tap(Resp => {
           this.toastr.success(this.translate.instant('Email sent successfully'), this.translate.instant('ALERT.TITLE.SUCCESS'));
           this.closeModal('content');
        },
        error => {
           this.toastr.error(error.error.error_msg, this.translate.instant('ALERT.TITLE.ERROR'));
        })).subscribe();
    }
      closeModal(content){
      this.modalService.dismissAll(content);
      this.emailList = [];
     }

     private validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
      }

}
