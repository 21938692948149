import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Regions, AppResponse } from "../_models";
import { RegionsService } from "./regions.service";

export class RegionsDataSource implements DataSource<Regions> {

    private regionsSubject = new BehaviorSubject<Regions[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private regionService: RegionsService) {

    }

    loadRegions(filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.regionService.findRegions(filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(regions => {
                //console.log(regionss);
                this.resultCount = (regions as AppResponse).recordsTotal;
                this.totalRecordsSubject.next((regions as AppResponse).recordsTotal)
                this.regionsSubject.next((regions as AppResponse).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<Regions[]> {
        console.log("Connecting data source");
        return this.regionsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.regionsSubject.complete();
        this.loadingSubject.complete();
    }

}
