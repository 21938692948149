// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, UserLoaded, UserRole } from '../../../../core/auth';
import * as sha1 from 'js-sha1';

//  import { MenuConfigService } from '../../../../core/_base/layout/services/menu-config.service';
// // import { Role } from '../../../../core/auth';
// import { MenuAsideService } from '../../../../core/_base/layout/services/menu-aside.service'
// import * as objectPath from 'object-path';


/**
 * ! Just example => Should be removed in development
 */


const DEMO_PARAMS = {
	EMAIL: '', //'admin@headshop.com',
	PASSWORD: '', //'Test12345'
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	ipAddress: string;

	// menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	// userRole$: Observable<Role>;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef
		// private menuService: MenuAsideService,
		// private menuConfig: MenuConfigService
	) {
		
		// alert(JSON.stringify(this.menuService.menuList$));
		this.unsubscribe = new Subject();
		localStorage.removeItem('accessToken');
		localStorage.removeItem('companyId');
		localStorage.removeItem('companyType');
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.initLoginForm();
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {

		// demo message to show
		if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			//this.authNoticeService.setNotice(initialNotice, 'info');
		}

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});

	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		
		// const menuItems: any[] = objectPath.get(this.menuConfig.getMenus(), 'aside.items');
		// this.menuService.menuList$.next(menuItems);
		//this.menuService.loadMenu();
		
		// this.menuService.menuList$;

		const authData = {
			email: controls['email'].value,
			password: sha1(controls['password'].value)
		};

		this.auth.getIP().pipe(tap(ipResp => {
			this.ipAddress = ipResp.data.ip_address;
			this.auth.deviceRegister(this.ipAddress).pipe(tap(devResp => {
				localStorage.setItem('accessToken', devResp.data.access_token);
				this.auth
					.login(authData.email, authData.password)
					.pipe(
						tap(respData => {
							if (respData) {
								// console.log(respData);
								localStorage.setItem('accessToken', respData.data.token);
								localStorage.setItem('companyId', respData.data.user.company.id);
								localStorage.setItem('companyType', respData.data.user.company.companyType);
								localStorage.setItem('permissions', JSON.stringify(respData.data.user.role));
								localStorage.setItem('userdetail', JSON.stringify(respData.data.user));

								this.store.dispatch(new Login({ authToken: respData.data.token }));
								//console.log("user role called");
								this.store.dispatch(new UserRole({ userRole: respData.data.user.role }));
								this.router.navigateByUrl('/'); // Main page
								// window.location.reload();
								this.cdr.detectChanges();
							} else {
								this.loading = false;
								this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
							}

						}, err => {
							//console.error(err.error.error_msg);
							this.loading = false;
							this.authNoticeService.setNotice(err.error.error_msg, 'danger');
						}),
						takeUntil(this.unsubscribe),
						finalize(() => {
							//this.loading = false;
							this.cdr.detectChanges();
						})
					).subscribe();
			},
			error => {
				//console.log(error);
			})).subscribe();
		},
		error => {
			//console.log(error);
		})).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
