import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Arvideos, ArvideosData } from "../_models";
import { ArvideosService } from "./arvideos.service";


export class ArvideosDataSource implements DataSource<ArvideosData> {

    private arSubject = new BehaviorSubject<ArvideosData[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;
    public hideAdd: boolean = true;

    constructor(private ArvideosService: ArvideosService) {

    }

    loadPADs(padId:number, filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.ArvideosService.findPADs(padId, filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(pads => {
                this.hideAdd = (pads as Arvideos).hideAdd;
                this.resultCount = (pads as Arvideos).recordsTotal;
                this.totalRecordsSubject.next((pads as Arvideos).recordsTotal);
                this.arSubject.next((pads as Arvideos).data);
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<ArvideosData[]> {
        return this.arSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.arSubject.complete();
        this.loadingSubject.complete();
    }

}
