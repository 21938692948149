export class PadsData {
  id: string;
  padTitle: string;
  padType: string;
  status: string;
  startsOn: string;
  endsOn: string;
  publishStartsOn: string;
  publishEndsOn: string;
  padFromTime: any;
  padToTime: any;
  pubFromTime: any;
  pubToTime: any;
  companyName: string;
  companyId: string;
  description: string;
  padLink: string;
  location: any;
  alertUsing: string;
  countryType: string;
  countryList: string;
  broadCastType: string;
  beaconIds: any;
  regionIds: any;
  latitude: number;
  longitude: number;
  padImages: any;
  storeDet: any;

  clear(currentDate: any): void {
      this.id = '';
      this.padTitle = '';
      this.padType = '';
      this.status = '';
      this.startsOn = currentDate;
      this.endsOn = currentDate;
      this.publishStartsOn = currentDate;
      this.publishEndsOn = currentDate;
      this.companyName = '';
      this.companyId = '';
      this.description = '';
      this.padLink = '';
      this.location = { fullAddress: "", houseNo: "" };
      this.alertUsing = '';
      this.countryType = '';
      this.countryList = '';
      this.broadCastType = '';
      this.beaconIds = [];
      this.regionIds = [];
      this.padFromTime = { hour: 12, minute: 0, meriden: "AM", format: 12 };
      this.padToTime = { hour: 12, minute: 0, meriden: "AM", format: 12 };
      this.pubFromTime = { hour: 12, minute: 0, meriden: "AM", format: 12 };
      this.pubToTime = { hour: 12, minute: 0, meriden: "AM", format: 12 };
      this.latitude = 38.3547735;
      this.longitude = -0.4758957000000237;
      this.padImages = [];
      this.storeDet = { id: '', companyName: '' };
  }
};

export class Pads {
  ts: number;
  success: boolean;
  error: boolean;
  data: PadsData[];
  recordsTotal: number;
  hideAdd: boolean;
};
