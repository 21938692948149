import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Billing, BillingListResp } from "../_models";
import { BillingService } from "./billing.service";

export class BillingDataSource implements DataSource<Billing> {

    private BillingSubject = new BehaviorSubject<Billing[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private BillingService: BillingService) {

    }

    loadBilling(filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number, status:number) {

        this.loadingSubject.next(true);
        this.BillingService.findBilling(filter, sortDirection, sortField, pageIndex, pageSize, status).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(Billing => {
                //alert(JSON.stringify(Billing))
                //console.log(systemUsers);
                this.resultCount = (Billing as BillingListResp).recordsTotal;
                this.totalRecordsSubject.next((Billing as BillingListResp).recordsTotal)
                this.BillingSubject.next((Billing as BillingListResp).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<Billing[]> {
        console.log("Connecting data source");
        return this.BillingSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.BillingSubject.complete();
        this.loadingSubject.complete();
    }

}
