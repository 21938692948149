import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, of, BehaviorSubject  } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Beacons, AppResponse } from "../_models";
import { BeaconService } from "./beacon.service";

export class BeaconDataSource implements DataSource<Beacons> {

    private beaconSubject = new BehaviorSubject<Beacons[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalRecordsSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalRecords$ = this.totalRecordsSubject.asObservable();
    public resultCount: number;

    constructor(private beaconService: BeaconService) {

    }

    loadBeacons(filter:string, sortDirection:string, sortField:string, pageIndex:number, pageSize:number) {

        this.loadingSubject.next(true);
        this.beaconService.findBeacons(filter, sortDirection, sortField, pageIndex, pageSize).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(beacons => {
                console.log(beacons);
                this.resultCount = (beacons as AppResponse).recordsTotal;
                this.totalRecordsSubject.next((beacons as AppResponse).recordsTotal)
                this.beaconSubject.next((beacons as AppResponse).data)
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<Beacons[]> {
        console.log("Connecting data source");
        return this.beaconSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.beaconSubject.complete();
        this.loadingSubject.complete();
    }

}
